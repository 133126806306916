import { IonPage } from "@ionic/react";

import { OverviewStep } from "types/listing.types";

import LaunchpadComponent from "components/@common/Launchpad";

const Launchpad = () => {
  return (
    <IonPage>
      <LaunchpadComponent highlightedStep={OverviewStep.Creation} />
    </IonPage>
  );
};

export default Launchpad;
