import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ListingResponse } from "types/listing.types";

import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import Guests from "components/@listing-detail/ListingDetails/ListingBasics/Guests";
import Nickname from "components/@listing-detail/ListingDetails/ListingBasics/Nickname";
import PropertyType from "components/@listing-detail/ListingDetails/ListingBasics/PropertyType";
import RoomDetails from "components/@listing-detail/ListingDetails/ListingBasics/RoomDetails";

interface Props {
  listing: ListingResponse["data"];
  isReadOnly: boolean;
}

const ListingBasics = ({ listing, isReadOnly }: Props) => {
  const intl = useIntl();

  const additionalAddressDetails = useMemo(
    () =>
      [
        listing.attributes.address.region,
        listing.attributes.neighborhood,
        listing.attributes.address.unit
          ? `${intl.formatMessage({ id: "listing_detail.details.unit" })} ${listing.attributes.address.unit}`
          : "",
        listing.attributes.address.floorNumber
          ? `${intl.formatMessage({ id: "listing_detail.details.floor" })} ${listing.attributes.address.floorNumber}`
          : "",
        listing.attributes.address.appartment
          ? `${intl.formatMessage({ id: "property_type.apartment.label" })} ${listing.attributes.address.appartment}`
          : "",
      ]
        .filter(Boolean)
        .join(" · "),
    [intl, listing],
  );

  return (
    <div className="listing-detail-block">
      <p className="subtitle">
        <FormattedMessage id="listing_detail.details.listing_basics" />
      </p>

      <ItemList>
        <Nickname listing={listing} isReadOnly={isReadOnly} />

        <ItemListRow title="listing_detail.details.address">
          <p className="margin-0">
            {listing.attributes.address.street},{" "}
            {listing.attributes.address.zipcode}{" "}
            {listing.attributes.address.city},{" "}
            {listing.attributes.address.country}
          </p>
        </ItemListRow>
        <ItemListRow title="complete_listing_about.subtitle2">
          <p className="margin-0">{additionalAddressDetails}</p>
        </ItemListRow>
        <PropertyType listing={listing} isReadOnly={isReadOnly} />
        <Guests listing={listing} isReadOnly={isReadOnly} />
        <RoomDetails listing={listing} isReadOnly={isReadOnly} />
      </ItemList>
    </div>
  );
};

export default ListingBasics;
