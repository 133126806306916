import { IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router";

import { isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";

import CompleteListingProvider from "components/@complete-listing/CompleteListingProvider";

import About from "./About";
import Amenities from "./Amenities";
import Compliance from "./Compliance";
import HouseRules from "./HouseRules";
import Launchpad from "./Launchpad";
import Overview from "./Overview";
import Photos from "./Photos";
import SleepingArrangements from "./SleepingArrangements";

const CompleteListing = () => {
  return (
    <CompleteListingProvider>
      <IonRouterOutlet id="complete-listing" animated={isNative}>
        <Route path={Routes.CompleteListingLaunchpad} component={Launchpad} />
        <Route path={Routes.CompleteListingAbout} component={About} />
        <Route path={Routes.CompleteListingAmenities} component={Amenities} />
        <Route
          path={Routes.CompleteListingSleepingArrangements}
          component={SleepingArrangements}
        />
        <Route path={Routes.CompleteListingPhotos} component={Photos} />
        <Route path={Routes.CompleteListingHouseRules} component={HouseRules} />
        <Route path={Routes.CompleteListingCompliance} component={Compliance} />
        <Route path={Routes.CompleteListingOverview} component={Overview} />
      </IonRouterOutlet>
    </CompleteListingProvider>
  );
};

export default CompleteListing;
