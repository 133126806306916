import "./ListingSidebar.scss";

import { menuController } from "@ionic/core/components";
import {
  IonContent,
  IonIcon,
  IonLabel,
  IonMenu,
  IonRouterLink,
} from "@ionic/react";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, matchPath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useListing } from "queries";

import DynamicShareButton from "components/@common/DynamicShareButton";

import { getListingId } from "../utils";

const ListingSidebar = () => {
  const listingId = getListingId() || " ";
  const isOverview = !!matchPath(location.pathname, {
    path: Routes.ListingOverview,
  });

  const { data } = useListing(listingId ?? "", {
    enabled: !!listingId,
  });

  const huswellPlatformUrl = useMemo(() => {
    return data?.relationships.otas.data.find(
      (ota) => ota.attributes.default === true,
    )?.attributes.externalUrl;
  }, [data]);

  const isActive = (path: string) => {
    return matchPath(location.pathname, { path });
  };

  const subRoutes = useMemo<
    Array<{ label: I18nKey; icon: string; route: string }>
  >(
    () => [
      {
        label: "listing_detail.nav.overview",
        icon: "assets/icons/icon-home.svg",
        route: generatePath(Routes.ListingOverview, { listingId }),
      },
      {
        label: "listing_detail.nav.calendar",
        icon: "assets/icons/icon-nav-calendar.svg",
        route: generatePath(Routes.ListingCalendar, { listingId }),
      },
      {
        label: "listing_detail.nav.details",
        icon: "assets/icons/icon-nav-listing.svg",
        route: generatePath(Routes.ListingDetails, { listingId }),
      },
      {
        label: "listing_detail.nav.marketing",
        icon: "assets/icons/icon-rocket.svg",
        route: generatePath(Routes.ListingMarketing, { listingId }),
      },
      {
        label: "listing_detail.nav.platforms",
        icon: "assets/icons/icon-external.svg",
        route: generatePath(Routes.ListingPlatforms, { listingId }),
      },
      {
        label: "listing_detail.nav.settings",
        icon: "assets/icons/icon-settings.svg",
        route: generatePath(Routes.ListingSettings, { listingId }),
      },
    ],
    [listingId],
  );

  const onNavigate = () => {
    menuController.close();

    // reset scroll on navigate between subpages
    const content = document.getElementById("listing-content");
    // @ts-expect-error this is an IonContent web component with this method available
    content?.scrollToTop();
  };

  return (
    <IonMenu contentId="listing-detail">
      <IonContent>
        <div className={`list ${isOverview ? "overview" : ""}`}>
          {subRoutes.map(({ icon, label, route }) => (
            <IonRouterLink routerLink={route} key={route} onClick={onNavigate}>
              <div className={`router-link ${isActive(route) ? "active" : ""}`}>
                <IonIcon icon={icon} />
                <IonLabel>
                  <FormattedMessage id={label} />
                </IonLabel>
              </div>
            </IonRouterLink>
          ))}

          <div className="share-button-mobile ion-hide-md-up">
            {huswellPlatformUrl && (
              <DynamicShareButton url={huswellPlatformUrl} isMobile />
            )}
          </div>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default ListingSidebar;
