import "./ReservationDetail.scss";

import { IonSpinner } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { useBlockReasons } from "queries/blocks";
import { useBlock, useReservation } from "queries/reservations";

import ErrorCard from "components/@common/ErrorCard";
import Block from "components/@reservations/ReservationDetail/ReservationDetail/Block";
import Reservation from "components/@reservations/ReservationDetail/ReservationDetail/Reservation";

import { getListingAndReservationIds } from "../../utils";

type Props = {
  listingId?: string;
  reservationId?: string;
  hideBack?: boolean;
  useDesktopButtons?: boolean;
  onClose?: () => void;
};

const ReservationDetail = ({
  hideBack,
  onClose,
  useDesktopButtons,
  ...props
}: Props) => {
  useBlockReasons(); // prefetch

  const { listingId, reservationId } = (
    props.listingId && props.reservationId
      ? props
      : getListingAndReservationIds()
  ) as { listingId: string; reservationId: string };

  const { data: reservation, error: reservationError } = useReservation(
    listingId ?? "",
    reservationId ?? "",
    {
      enabled: !!listingId && !!reservationId,
    },
  );

  const { data: block, error: blockError } = useBlock(
    listingId ?? "",
    reservationId ?? "",
    {
      enabled: !!listingId && !!reservationId,
    },
  );

  const isError = reservationError && blockError;

  if (!reservation && !block && !isError) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <IonSpinner style={{ width: 50, height: 50 }} />
      </div>
    );
  }

  if (isError) {
    return <ErrorCard title={<FormattedMessage id="common.error_unknown" />} />;
  }

  if (reservation)
    return (
      <Reservation
        reservation={reservation}
        hideBack={hideBack}
        listingId={listingId}
        reservationId={reservationId}
        useDesktopButtons={useDesktopButtons}
        onClose={onClose}
      />
    );
  if (block)
    return (
      <Block
        block={block}
        listingId={listingId}
        reservationId={reservationId}
      />
    );

  return <ErrorCard title={<FormattedMessage id="common.error_unknown" />} />;
};

export default ReservationDetail;
