import { IonPage } from "@ionic/react";

import SuccessComponent from "components/@review-listing/Success";

const Success = () => {
  return (
    <IonPage>
      <SuccessComponent />
    </IonPage>
  );
};

export default Success;
