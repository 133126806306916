import { IonContent, IonPage } from "@ionic/react";

import PreferencesComponent from "components/@preferences/Preferences";

const Preferences = () => {
  return (
    <IonPage>
      <IonContent>
        <PreferencesComponent />
      </IonContent>
    </IonPage>
  );
};

export default Preferences;
