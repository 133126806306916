import { IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router";

import { isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";

import CreateListingProvider from "components/@create-listing/CreateListingProvider";

import Address from "./Address";
import Checkout from "./Checkout";
import ConfirmPlan from "./ConfirmPlan";
import Launchpad from "./Launchpad";
import PropertyDetails from "./PropertyDetails";
import Requirements from "./Requirements";
import RequirementsFail from "./RequirementsFail";
import Segmentation from "./Segmentation";
import Success from "./Success";
import Url from "./Url";

const CreateListing = () => {
  return (
    <CreateListingProvider>
      <IonRouterOutlet id="create-listing" animated={isNative}>
        <Route path={Routes.CreateListingLaunchpad} component={Launchpad} />
        <Route path={Routes.CreateListingAddress} component={Address} />
        <Route
          path={Routes.CreateListingRequirements}
          component={Requirements}
        />
        <Route
          path={Routes.CreateListingSegmentation}
          component={Segmentation}
        />
        <Route
          path={Routes.CreateListingRequirementsFail}
          component={RequirementsFail}
        />
        <Route
          path={Routes.CreateListingPropertyDetails}
          component={PropertyDetails}
        />
        <Route path={Routes.CreateListingUrl} component={Url} />
        <Route path={Routes.CreateListingConfirmPlan} component={ConfirmPlan} />
        <Route path={Routes.CreateListingCheckout} component={Checkout} />
        <Route path={Routes.CreateListingSuccess} component={Success} />
      </IonRouterOutlet>
    </CreateListingProvider>
  );
};

export default CreateListing;
