import { IonInput, useIonAlert } from "@ionic/react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useInviteOwner } from "queries/listings/useInviteOwner";
import { useRevokeOwnerAccess } from "queries/listings/useRevokeOwnerAccess";
import { ListingResponse, Owner, OwnerType } from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";
import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import LoadingButton from "components/@common/LoadingButton";
import Modal from "components/@common/Modal";

type Props = {
  listing: ListingResponse["data"];
  isReadOnly: boolean;
};

const Owners = ({ listing, isReadOnly }: Props) => {
  const intl = useIntl();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [inviteMail, setInviteMail] = useState("");
  const {
    inviteOwner,
    isLoading: isInvitingOwner,
    error: inviteError,
  } = useInviteOwner();
  const { revokeOwnerAccess, isLoading: isRevokingAccess } =
    useRevokeOwnerAccess();

  const owners = listing.relationships.owners.data;

  const [presentAlert] = useIonAlert();
  const revokeUser = async (owner: Owner) => {
    presentAlert({
      header: intl.formatMessage(
        { id: "listing.settings.owners.revoke.confirm" },
        { email: owner.attributes.email },
      ),
      cssClass: "delete-alert",
      buttons: [
        { text: intl.formatMessage({ id: "button.cancel" }) },
        {
          text: intl.formatMessage({ id: "button.revoke" }),
          handler: () =>
            revokeOwnerAccess({ ownerId: owner.id, listingId: listing.id }),
        },
      ],
    });
  };

  const onSubmitInvite = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    inviteOwner(
      { email: inviteMail, id: listing.id },
      {
        onSuccess: () => {
          setIsDialogOpen(false);
          setInviteMail("");
        },
      },
    );
  };

  return (
    <>
      <ItemList
        title="listing.settings.owners"
        actionKey="listing.settings.owners.invite"
        onClick={isReadOnly ? undefined : () => setIsDialogOpen(true)}
      >
        {/* @TODO hide revoke button if you're not the owner */}
        {owners.map((owner) => {
          const isOwner = owner.attributes.ownerType === OwnerType.MAIN;
          return (
            <ItemListRow
              key={owner.id}
              actionKey={
                isReadOnly ? undefined : "listing.settings.owners.revoke"
              }
              isLoading={isRevokingAccess}
              onActionClick={isOwner ? undefined : () => revokeUser(owner)}
            >
              <div style={{ display: "flex", gap: 4, flexDirection: "column" }}>
                <p className="margin-0" style={{ fontWeight: 600 }}>
                  {owner.attributes.firstName} {owner.attributes.lastName}
                </p>
                <p className="margin-0">{owner.attributes.email}</p>

                <p
                  className="margin-0"
                  style={{
                    fontSize: 13,
                    fontWeight: 600,
                    textTransform: "uppercase",
                    color: isOwner
                      ? "var(--ion-color-primary)"
                      : "var(--ion-text-color-step-800)",
                  }}
                >
                  <FormattedMessage
                    id={
                      isOwner
                        ? "listing.settings.owners.owner"
                        : "listing.settings.owners.view_access"
                    }
                  />
                </p>
              </div>
            </ItemListRow>
          );
        })}
      </ItemList>

      <Modal
        isOpen={isDialogOpen}
        onDidDismiss={() => setIsDialogOpen(false)}
        continueButton={
          <LoadingButton
            data-testid="invite-button"
            type="submit"
            shape="round"
            form="owner-reservation-form"
            isLoading={isInvitingOwner}
            disabled={isInvitingOwner}
          >
            <FormattedMessage id="listing.settings.owners.invite.button" />
          </LoadingButton>
        }
      >
        <h5 style={{ marginBottom: 16 }}>
          <FormattedMessage id="listing.settings.owners.invite.title" />
        </h5>

        <p style={{ marginBottom: 16 }} className="margin-0">
          <FormattedMessage id="listing.settings.owners.invite.description" />
        </p>

        <ul style={{ marginBottom: 24 }}>
          <li>
            <FormattedMessage id="listing.settings.owners.invite.permissions.1" />
          </li>
          <li>
            <FormattedMessage id="listing.settings.owners.invite.permissions.2" />
          </li>
          <li>
            <FormattedMessage id="listing.settings.owners.invite.permissions.3" />
          </li>
        </ul>

        <form id="owner-reservation-form" onSubmit={onSubmitInvite}>
          {inviteError && (
            <ErrorCard title={<FormattedMessage id="common.error_unknown" />} />
          )}

          <IonInput
            value={inviteMail}
            label={intl.formatMessage({ id: "reservation_detail.email" })}
            type="email"
            required
            onIonInput={(e) =>
              setInviteMail((e.currentTarget as HTMLInputElement).value)
            }
            fill="solid"
            labelPlacement="floating"
          />
        </form>
      </Modal>
    </>
  );
};

export default Owners;
