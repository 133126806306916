import "./ListingDetails.scss";

import { FormattedMessage } from "react-intl";

import { useListing } from "queries";
import { OwnerType } from "types/listing.types";

import LoadingIndicator from "components/@common/LoadingIndicator";
import Amenities from "components/@listing-detail/ListingDetails/Amenities/Amenities";
import HouseRules from "components/@listing-detail/ListingDetails/HouseRules/HouseRules";
import ListingBasics from "components/@listing-detail/ListingDetails/ListingBasics/ListingBasics";
import ListingBreadcrumbs from "components/@listing-detail/ListingDetails/ListingBreadcrumbs/ListingBreadcrumbs";
import Regulations from "components/@listing-detail/ListingDetails/Regulations/Regulations";
import SleepingArrangements from "components/@listing-detail/ListingDetails/SleepingArrangements/SleepingArrangements";
import { getListingId, useOwnerType } from "components/@listing-detail/utils";

const ListingDetails = () => {
  const listingId = getListingId();
  const { data: listing, isLoading } = useListing(listingId ?? "", {
    enabled: !!listingId,
  });

  const isReadOnly = useOwnerType(listing) === OwnerType.SUB;

  return (
    <div>
      <ListingBreadcrumbs listing={listing} />
      <LoadingIndicator
        isOpen={isLoading && !!listingId}
        testId="ListingDetails"
      />

      <div className="listing-details">
        <h5>
          <FormattedMessage id="listing_detail.nav.details" />
        </h5>

        {listing && (
          <>
            <ListingBasics listing={listing} isReadOnly={isReadOnly} />
            <SleepingArrangements listing={listing} isReadOnly={isReadOnly} />
            <Amenities listing={listing} isReadOnly={isReadOnly} />
            <HouseRules listing={listing} isReadOnly={isReadOnly} />
            <Regulations listing={listing} isReadOnly={isReadOnly} />
          </>
        )}
      </div>
    </div>
  );
};

export default ListingDetails;
