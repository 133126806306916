import { captureMessage } from "@sentry/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { useUpdateListing } from "queries";
import {
  BedType,
  ListingResponse,
  RoomType,
  SleepingArrangement,
} from "types/listing.types";

import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import LinkUnstyled from "components/@common/LinkUnstyled";
import LoadingButton from "components/@common/LoadingButton";
import SleepingArrangementsForm from "components/@complete-listing/SleepingArrangements/SleepingArrangementsForm";

interface Props {
  listing: ListingResponse["data"];
  isReadOnly: boolean;
}

const SleepingArrangements = ({ listing, isReadOnly }: Props) => {
  const intl = useIntl();
  const [isEditing, setIsEditing] = useState(false);
  const { updateListing, isLoading, error } = useUpdateListing();

  const bedrooms = listing.attributes.sleepingArrangements.filter(
    ({ roomType }) => roomType === RoomType.Bedroom,
  );
  const sharedSpaces = listing.attributes.sleepingArrangements.filter(
    ({ roomType }) => roomType === RoomType.SharedSpace,
  );

  const formatBedTypes = (bedTypes: Array<BedType>) => {
    if (bedTypes.length === 0) return "";
    return bedTypes
      .map((bedType) => intl.formatMessage({ id: `bed_type.${bedType}` }))
      .join(" · ");
  };

  const methods = useForm<{
    bedrooms: number;
    sleepingArrangements: Array<SleepingArrangement>;
  }>({
    defaultValues: {
      bedrooms: listing.attributes.bedrooms,
      sleepingArrangements: listing.attributes.sleepingArrangements,
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const onSubmit = ({
    sleepingArrangements,
    bedrooms,
    beds,
  }: {
    sleepingArrangements: Array<SleepingArrangement>;
    bedrooms: number;
    beds: number;
  }) => {
    updateListing(
      {
        id: listing.id,
        attributes: { sleepingArrangements, bedrooms, beds },
      },
      {
        onSuccess: () => {
          methods.reset(methods.getValues());
          setIsEditing(false);
        },
        onError: (error) => {
          captureMessage(
            "Error updating sleeping arrangements in listing detail",
            { level: "error", extra: { error } },
          );
        },
      },
    );
  };

  return (
    <div className="listing-detail-block">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="subtitle">
          <FormattedMessage id="complete_listing_overview.sleeping_arrangements.title" />
        </p>
        {!isReadOnly && (
          <LinkUnstyled onClick={() => setIsEditing((isEditing) => !isEditing)}>
            <FormattedMessage
              id={isEditing ? "button.cancel" : "common.edit"}
            />
          </LinkUnstyled>
        )}
      </div>
      <ItemList>
        {isEditing ? (
          <FormProvider {...methods}>
            <div style={{ padding: "12px 0" }}>
              <SleepingArrangementsForm error={error} onSubmit={onSubmit} />

              <LoadingButton
                isLoading={isLoading}
                shape="round"
                style={{ height: 48, marginTop: 36 }}
                type="submit"
                form="hook-form-arrangements"
              >
                <FormattedMessage id="common.save" />
              </LoadingButton>
            </div>
          </FormProvider>
        ) : (
          <>
            {bedrooms.map((arrangement, index) => (
              <ItemListRow
                title="complete_listing_sleeping_arrangements.bedroom"
                values={{ count: index + 1 }}
                key={index}
              >
                {formatBedTypes(arrangement.bedTypes)}
              </ItemListRow>
            ))}
            {sharedSpaces.map((arrangement, index) => (
              <ItemListRow
                title="complete_listing_sleeping_arrangements.shared_space"
                values={{ count: index + 1 }}
                key={index}
              >
                {formatBedTypes(arrangement.bedTypes)}
              </ItemListRow>
            ))}
          </>
        )}
      </ItemList>
    </div>
  );
};

export default SleepingArrangements;
