import { captureMessage } from "@sentry/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { propertyTypeKeys } from "constants/listing.constants";
import { useUpdateListing } from "queries";
import {
  ListingResponse,
  PropertyKind as PropertyKindEnum,
  PropertyType as PropertyTypeEnum,
} from "types/listing.types";

import ItemListRow from "components/@common/ItemList/ItemListRow";
import LoadingButton from "components/@common/LoadingButton";
import FormSelect from "components/@form/FormSelect";

interface Props {
  listing: ListingResponse["data"];
  isReadOnly: boolean;
}

const PropertyType = ({ listing, isReadOnly }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const intl = useIntl();

  const { updateListing, isLoading } = useUpdateListing();

  const methods = useForm<{
    propertyKind: PropertyKindEnum;
    propertyType: PropertyTypeEnum;
  }>({
    defaultValues: {
      propertyKind: listing.attributes.propertyKind,
      propertyType: listing.attributes.propertyType,
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const { propertyKind, propertyType } = methods.watch();

  const handleAction = () => {
    if (isEditing) methods.reset();
    setIsEditing((isEditing) => !isEditing);
  };

  const handleSubmit = ({
    propertyKind,
    propertyType,
  }: {
    propertyKind: PropertyKindEnum;
    propertyType: PropertyTypeEnum;
  }) => {
    updateListing(
      { id: listing.id, attributes: { propertyKind, propertyType } },
      {
        onSuccess: () => {
          methods.reset(methods.getValues());
          setIsEditing(false);
        },
        onError: (error) => {
          captureMessage("Error updating property type in listing detail", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  const propertyKindOptions = Object.values(PropertyKindEnum).map((value) => ({
    label: intl.formatMessage({ id: propertyTypeKeys[value] }),
    value,
  }));

  const propertyTypeOptions = Object.values(PropertyTypeEnum).map((value) => ({
    label: intl.formatMessage({ id: propertyTypeKeys[value] }),
    value,
  }));

  return (
    <ItemListRow
      title="listing_detail.details.property_type"
      actionKey={isEditing ? "button.cancel" : "common.edit"}
      isLoading={isLoading}
      onActionClick={isReadOnly ? undefined : handleAction}
    >
      {isEditing ? (
        <FormProvider {...methods}>
          <div>
            <div style={{ display: "flex", gap: 16 }}>
              <FormSelect
                name="propertyKind"
                options={propertyKindOptions}
                placeholder={
                  <FormattedMessage id="listing_detail.details.property_kind" />
                }
              />

              <FormSelect
                name="propertyType"
                options={propertyTypeOptions}
                placeholder={
                  <FormattedMessage id="listing_detail.details.property_type" />
                }
              />
            </div>

            <LoadingButton
              isLoading={isLoading}
              shape="round"
              style={{ height: 48, marginTop: 12 }}
              onClick={methods.handleSubmit(handleSubmit)}
            >
              <FormattedMessage id="common.save" />
            </LoadingButton>
          </div>
        </FormProvider>
      ) : (
        <p className="margin-0">
          <FormattedMessage id={propertyTypeKeys[propertyKind]} />
          {" · "}
          <FormattedMessage id={propertyTypeKeys[propertyType]} />
        </p>
      )}
    </ItemListRow>
  );
};

export default PropertyType;
