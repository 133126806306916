import { IonContent, IonPage } from "@ionic/react";

import ReservationDetailComponent from "components/@reservations/ReservationDetail/ReservationDetail/ReservationDetail";

const ReservationDetail = () => {
  return (
    <IonPage>
      <IonContent className="reservation-detail-page-ion-content">
        <ReservationDetailComponent />
      </IonContent>
    </IonPage>
  );
};

export default ReservationDetail;
