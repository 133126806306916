export const MAX_FILES = 50;
export const FILES_MAX_SIZE_MB = 25;
export const PHOTOS_ALLOWED_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
  "image/heic",
  "image/heif",
];
