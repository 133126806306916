import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { useEffect } from "react";

const AppUrlListener = () => {
  useEffect(() => {
    const handleAppUrlOpen = (event: URLOpenListenerEvent) => {
      const url = event.url;

      if (Capacitor.getPlatform() === "android") {
        // On Android, use the URL directly
        window.location.replace(url);
      } else if (Capacitor.getPlatform() === "ios") {
        // On iOS, replace 'https' with 'capacitor'
        const iosUrl = url.replace(/^https?:\/\//i, "capacitor://");
        window.location.replace(iosUrl);
      }
    };

    // Add the listener for app open URL events
    App.addListener("appUrlOpen", handleAppUrlOpen);

    return () => {
      // Clean up the listener when the component is unmounted
      App.removeAllListeners();
    };
  }, []);

  return null;
};

export default AppUrlListener;
