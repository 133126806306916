import { captureMessage } from "@sentry/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { useUpdateListing } from "queries";
import { FinancialValues, ListingResponse } from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";
import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import LoadingButton from "components/@common/LoadingButton";
import { FormIonInput } from "components/@form/FormIonInput";
import { getListingId } from "components/@listing-detail/utils";

type Props = {
  listing: ListingResponse["data"];
  isReadOnly: boolean;
};

const Billing = ({ listing, isReadOnly }: Props) => {
  const intl = useIntl();
  const [isEditing, setIsEditing] = useState(false);
  const { updateListing, error, isLoading } = useUpdateListing();

  const methods = useForm<FinancialValues>({
    defaultValues: listing.attributes.financial || {
      name: "",
      billingEmail: "",
      vatNumber: "",
      billingAddress: "",
    },

    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const handleAction = () => {
    if (isEditing) {
      methods.reset(listing.attributes.financial);
    }

    setIsEditing((isEditing) => !isEditing);
  };

  const onSubmit = (financial: FinancialValues) => {
    const listingId = getListingId();

    updateListing(
      { id: listingId, attributes: { financial } },
      {
        onSuccess: () => {
          methods.reset(financial);
          setIsEditing(false);
        },
        onError: (error) => {
          captureMessage(
            "Error updating financial billing values in listing detail",
            { level: "error", extra: { error } },
          );
        },
      },
    );
  };

  const values = methods.getValues();
  const isEmpty = Object.values(values).every((value) => !value);

  return (
    <ItemList
      title="listing.settings.billing"
      actionKey={isEditing ? "button.cancel" : "common.edit"}
      onClick={isReadOnly ? undefined : handleAction}
    >
      {isEditing ? (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            style={{
              marginTop: 12,
              display: "flex",
              gap: 24,
              flexDirection: "column",
            }}
          >
            {error && (
              <ErrorCard
                title={<FormattedMessage id="common.error_unknown" />}
              />
            )}

            <FormIonInput
              name="name"
              data-testid="settings-name"
              aria-label="name"
              fill="solid"
              label={intl.formatMessage({
                id: "listing.settings.billing.name",
              })}
              labelPlacement="floating"
              required
            />
            <FormIonInput
              name="vatNumber"
              data-testid="settings-vatNumber"
              aria-label="VAT number"
              fill="solid"
              label={intl.formatMessage({
                id: "listing.settings.billing.vat",
              })}
              hint={intl.formatMessage({
                id: "listing.settings.billing.vat.hint",
              })}
              labelPlacement="floating"
            />
            <FormIonInput
              name="billingAddress"
              data-testid="settings-billingAddress"
              aria-label="Billing Address"
              fill="solid"
              label={intl.formatMessage({
                id: "listing.settings.billing.billing_address",
              })}
              labelPlacement="floating"
              required
            />
            <FormIonInput
              name="billingEmail"
              data-testid="settings-billingEmail"
              aria-label="Billing Email"
              fill="solid"
              label={intl.formatMessage({
                id: "listing.settings.billing.billing_email",
              })}
              labelPlacement="floating"
              type="email"
              required
            />

            <LoadingButton
              isLoading={isLoading}
              shape="round"
              style={{ height: 48, width: "fit-content" }}
              type="submit"
            >
              <FormattedMessage id="common.save" />
            </LoadingButton>
          </form>
        </FormProvider>
      ) : (
        <ItemListRow>
          {isEmpty ? (
            <FormattedMessage id="complete_listing_overview.not_set" />
          ) : (
            <div>
              <p className="margin-0" style={{ fontWeight: 600 }}>
                {[values.name, values.vatNumber].filter(Boolean).join(", ")}
              </p>
              <p className="margin-0">
                {[values.billingAddress, values.billingEmail]
                  .filter(Boolean)
                  .join(", ")}
              </p>
            </div>
          )}
        </ItemListRow>
      )}
    </ItemList>
  );
};

export default Billing;
