import "./ConfirmPlan.scss";

import { IonButton, useIonRouter } from "@ionic/react";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useUpdateListing } from "queries";
import { Flow } from "types/flow.types";
import { CreateListingProviderValues, PricingPlan } from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";
import StepperContainer from "components/@common/StepperContainer";

import PlanCard from "./PlanCard";

const ConfirmPlan = () => {
  const intl = useIntl();
  const router = useIonRouter();
  const { updateListing, error } = useUpdateListing();
  const [loadingPlan, setLoadingPlan] = useState<PricingPlan>();
  const { setValue } = useFormContext<CreateListingProviderValues>();

  const listingId = useWatch({ name: "listingId" });

  const handleSubmit = (plan: PricingPlan) => {
    setLoadingPlan(plan);
    setValue("pricingPlan", plan);

    updateListing(
      {
        id: listingId,
        attributes: {
          pricingPlan: plan,
          pricingPlanCommission: Number(
            intl.formatMessage({ id: `${plan}_plan.percentage.number` }),
          ),
        },
      },
      {
        onSuccess: () => {
          setLoadingPlan(undefined);
          router.push(
            generatePath(Routes.CreateListingCheckout, {
              listingId,
            }),
          );
        },
        onError: () => {
          setLoadingPlan(undefined);
        },
      },
    );
  };

  const handleBack = () => {
    router.push(
      generatePath(Routes.CreateListingRequirements, {
        listingId,
      }),
      "back",
    );
  };

  return (
    <StepperContainer
      flow={Flow.CreateListing}
      route={Routes.CreateListingConfirmPlan}
      fullWidthContent
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
      continueButton={
        <i>
          <FormattedMessage id="create_listing_confirm_plan.select" />
        </i>
      }
    >
      <div className="stepper-container">
        <h2 className="margin-24">
          <FormattedMessage id="create_listing_confirm_plan.title" />
        </h2>

        <p className="create-listing-description">
          <FormattedMessage id="create_listing_confirm_plan.description" />
        </p>

        {error && (
          <div className="margin-top-16">
            <ErrorCard
              title={<FormattedMessage id={"common.error_unknown"} />}
            />
          </div>
        )}
      </div>

      <div
        // className="grid-plan"
        style={{
          display: "flex",
          marginBottom: 40,
          width: "100%",
          maxWidth: "568px",
        }}
      >
        <PlanCard
          plan={PricingPlan.Manual}
          onSelect={handleSubmit}
          isLoading={loadingPlan === PricingPlan.Manual}
        />
        {/* <PlanCard
          plan={PricingPlan.Starter}
          onSelect={handleSubmit}
          isLoading={loadingPlan === PricingPlan.Starter}
        />
        <PlanCard
          plan={PricingPlan.Professional}
          onSelect={handleSubmit}
          isLoading={loadingPlan === PricingPlan.Professional}
        />
        <PlanCard
          plan={PricingPlan.Premium}
          onSelect={handleSubmit}
          isLoading={loadingPlan === PricingPlan.Premium}
        /> */}
      </div>

      <p className="plan-link">
        <FormattedMessage
          id="create_listing_url.link"
          values={{
            a: (chunks) => (
              <a
                data-testid="compare-plans-link"
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.huswell.be/nl"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </p>
    </StepperContainer>
  );
};

export default ConfirmPlan;
