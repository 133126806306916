import "./LinkUnstyled.scss";

import { ReactNode } from "react";

interface Props {
  onClick: () => void;
  children?: ReactNode;
  id?: string;
}

const LinkUnstyled = ({ onClick, children, id }: Props) => {
  return (
    <button onClick={onClick} className="link-unstyled" id={id} type="button">
      {children}
    </button>
  );
};

export default LinkUnstyled;
