import { FormattedMessage } from "react-intl";

import LinkUnstyled from "../LinkUnstyled";

interface Props {
  children: React.ReactNode;
  title?: I18nKey;
  values?: Record<string, string | number>;
  actionKey?: I18nKey;
  isLoading?: boolean;
  onActionClick?: () => void;
}

const ItemListRow = ({
  children,
  title,
  values,
  actionKey,
  isLoading,
  onActionClick,
}: Props) => {
  const actionButton = actionKey && onActionClick && (
    <LinkUnstyled onClick={isLoading ? () => {} : onActionClick}>
      <FormattedMessage id={actionKey} />
    </LinkUnstyled>
  );

  return (
    <>
      <div className="item-list-row">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {title && (
            <>
              <p className="margin-0 bold" style={{ marginBottom: 4 }}>
                <FormattedMessage id={title} values={values} />
              </p>
              {actionButton}
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {children}
          {!title && actionButton}
        </div>
      </div>
      <hr />
    </>
  );
};

export default ItemListRow;
