import { IonPage, IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router";

import { isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";

import CreateReservationProvider from "components/@create-reservation/CreateReservationProvider";

import Details from "./Details";
import Success from "./Overview";
import Type from "./Type";

const CreateListing = () => {
  return (
    <CreateReservationProvider>
      {/* @TODO this IonPage probably messes up the animations in Capacitor,
      I think it needs to be positioned inside of the Routes themselves but that also causes issues because of navigation between tabs.
      The entire navigation setup might need to be revisited (build it up from scratch following the docs 1 to 1). */}
      <IonPage>
        <IonRouterOutlet id="create-reservation" animated={isNative}>
          <Route path={Routes.CreateReservationType} component={Type} />
          <Route path={Routes.CreateReservationDetails} component={Details} />
          <Route path={Routes.CreateReservationOverview} component={Success} />
        </IonRouterOutlet>
      </IonPage>
    </CreateReservationProvider>
  );
};

export default CreateListing;
