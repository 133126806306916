import { captureMessage } from "@sentry/react";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { useAmenities, useUpdateListing } from "queries";
import { ListingResponse } from "types/listing.types";

import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import LinkUnstyled from "components/@common/LinkUnstyled";
import LoadingButton from "components/@common/LoadingButton";
import AmenitiesForm from "components/@complete-listing/Amenities/AmenitiesForm";

interface Props {
  listing: ListingResponse["data"];
  isReadOnly: boolean;
}

const Amenities = ({ listing, isReadOnly }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { updateListing, isLoading, error } = useUpdateListing();
  useAmenities(); // preload for edit

  const methods = useForm<{
    amenities: Array<string>;
  }>({
    defaultValues: { amenities: listing.attributes.amenities },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const amenities = methods.watch("amenities");

  const essentials = amenities?.filter((a) => a.startsWith("essentials."));
  const favourites = amenities?.filter((a) => a.startsWith("favourites."));
  const other = amenities?.filter(
    (a) => !a.startsWith("essentials.") && !a.startsWith("favourites."),
  );

  const onSubmit = ({ amenities }: { amenities: Array<string> }) => {
    updateListing(
      {
        id: listing.id,
        attributes: { amenities },
      },
      {
        onSuccess: () => {
          methods.reset(methods.getValues());
          setIsEditing(false);
        },
        onError: (error) => {
          captureMessage("Error updating amenities in listing detail", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  return (
    <div className="listing-detail-block">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="subtitle">
          <FormattedMessage id="complete_listing_overview.amenities.title" />
        </p>
        {!isReadOnly && (
          <LinkUnstyled onClick={() => setIsEditing((isEditing) => !isEditing)}>
            <FormattedMessage
              id={isEditing ? "button.cancel" : "common.edit"}
            />
          </LinkUnstyled>
        )}
      </div>
      <ItemList>
        {isEditing ? (
          <FormProvider {...methods}>
            <div style={{ padding: "12px 0" }}>
              <AmenitiesForm onSubmit={onSubmit} error={error} />

              <LoadingButton
                isLoading={isLoading}
                shape="round"
                style={{ height: 48, marginTop: 36 }}
                type="submit"
                form="hook-form-amenities"
              >
                <FormattedMessage id="common.save" />
              </LoadingButton>
            </div>
          </FormProvider>
        ) : (
          <>
            {!!essentials.length && (
              <ItemListRow title="complete_listing_amenities.essentials">
                <p className="margin-0">
                  {essentials.map((amenity, index) => (
                    <React.Fragment key={index}>
                      <FormattedMessage id={amenity as I18nKey} />
                      {index < essentials.length - 1 && " · "}
                    </React.Fragment>
                  ))}
                </p>
              </ItemListRow>
            )}
            {!!favourites.length && (
              <ItemListRow title="complete_listing_amenities.favorites">
                <p className="margin-0">
                  {favourites.map((amenity, index) => (
                    <React.Fragment key={index}>
                      <FormattedMessage id={amenity as I18nKey} />
                      {index < favourites.length - 1 && " · "}
                    </React.Fragment>
                  ))}
                </p>
              </ItemListRow>
            )}
            {!!other.length && (
              <ItemListRow title="complete_listing_amenities.other">
                <p className="margin-0">
                  {other.map((amenity, index) => (
                    <React.Fragment key={index}>
                      <FormattedMessage id={amenity as I18nKey} />
                      {index < other.length - 1 && " · "}
                    </React.Fragment>
                  ))}
                </p>
              </ItemListRow>
            )}
          </>
        )}
      </ItemList>
    </div>
  );
};

export default Amenities;
