import "./AmenitiesForm.scss";

import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import {
  amenitiesKeys,
  essentialsKeys,
  favouritesKeys,
  otherKeys,
} from "constants/listing.constants";
import { useAmenities } from "queries";
import { CompleteListingProviderValues } from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";
import LoadingIndicator from "components/@common/LoadingIndicator";
import FormAccordion from "components/@form/FormAccordion";
import FormRadioGroupCard from "components/@form/FormRadioGroupCard";
import { Option } from "components/@form/types";

import { CompleteListingAmenitiesFormFields } from "../types";

interface Props {
  error: Error | null;
  onSubmit: (values: { amenities: Array<string> }) => void;
}

const generateOptions = (keys: Record<string, I18nKey>, data?: Array<string>) =>
  data?.map((value) => ({
    label: keys[value] ?? value,
    value: keys[value] ?? value,
  })) ?? [];

const AmenitiesForm = ({ error, onSubmit }: Props) => {
  const intl = useIntl();
  const { data, isLoading } = useAmenities();
  const { handleSubmit } = useFormContext<CompleteListingProviderValues>();

  const essentialsOptions: Array<Option> = useMemo(
    () => generateOptions(essentialsKeys, data?.essentials),
    [data],
  );

  const favouritesOptions: Array<Option> = useMemo(
    () => generateOptions(favouritesKeys, data?.favourites),
    [data],
  );

  const amenitiesList = useMemo(() => {
    if (!data) return {};
    const { essentials, favourites, ...rest } = data;
    return Object.fromEntries(
      Object.entries(rest).map(([key, values]) => [
        key,
        generateOptions(otherKeys, values),
      ]),
    );
  }, [data]);

  return (
    <>
      <LoadingIndicator isOpen={isLoading} testId="AmenitiesForm" />

      {error && (
        <ErrorCard title={<FormattedMessage id="common.error_unknown" />} />
      )}
      <form
        id="hook-form-amenities"
        className="complete-listing-amenities"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="subtitle bold">
          <FormattedMessage id="complete_listing_amenities.essentials" />
        </p>

        <FormRadioGroupCard
          name={CompleteListingAmenitiesFormFields.Amenities}
          options={essentialsOptions}
          type="Amenity"
          multiple
        />

        <p className="subtitle bold">
          <FormattedMessage id="complete_listing_amenities.favorites" />
        </p>

        <FormRadioGroupCard
          name={CompleteListingAmenitiesFormFields.Amenities}
          options={favouritesOptions}
          type="Amenity"
          multiple
        />

        <p className="subtitle bold">
          <FormattedMessage id="complete_listing_amenities.other" />
        </p>

        <p>
          <FormattedMessage id="complete_listing_all_amenities.description" />
        </p>

        {Object.entries(amenitiesList).map(([category, options]) => (
          <div key={category} className="amenities-accordion-spacing">
            <FormAccordion
              name={CompleteListingAmenitiesFormFields.Amenities}
              options={options}
              label={intl.formatMessage({
                id: amenitiesKeys[category] || category,
              })}
            />
          </div>
        ))}
      </form>
    </>
  );
};

export default AmenitiesForm;
