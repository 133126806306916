import "./Reservations.scss";

import { IonButton, useIonRouter } from "@ionic/react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";

import ReservationsTable from "./ReservationsTable";

const Reservations = () => {
  const router = useIonRouter();

  const handleCreateReservation = () => {
    router.push(
      generatePath(
        Routes.CreateReservationType + `?backroute=${Routes.Reservations}`,
      ),
      "forward",
    );
  };

  return (
    <div className="reservations-page">
      <div className="header">
        <h5>
          <FormattedMessage id="nav.reservations" />
        </h5>

        <IonButton
          className="continue-button-stepper"
          data-testid="create-reservation"
          shape="round"
          onClick={handleCreateReservation}
        >
          <FormattedMessage id="listing_overview.add_reservation" />
        </IonButton>
      </div>
      <ReservationsTable />
    </div>
  );
};

export default Reservations;
