import "./ReservationDetail.scss";

import { IonButton, IonIcon, useIonAlert, useIonRouter } from "@ionic/react";
import { captureMessage } from "@sentry/react";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import {
  useDeleteReservation,
  useRequestCancelReservation,
  useUpdateReservation,
} from "queries/reservations";
import { ReservationType } from "types/reservation.types";
import { GuestyReservation } from "types/reservation.types";

import LoadingButton from "components/@common/LoadingButton";
import Modal from "components/@common/Modal";
import SuccessCard from "components/@common/SuccessCard";
import ReservationEditDetailsForm from "components/@create-reservation/Details/ReservationEditDetailsForm";
import { Form } from "components/@form/Form";
import FormIonTextArea from "components/@form/FormIonTextArea";
import ReservationDelayDisclaimer from "components/@reservations/ReservationDetail/ReservationDelayDisclaimer";

import ReservationStatusChip from "../../ReservationStatusChip/ReservationStatusChip";
import { ReservationStatus } from "../../types";
import { getReservationStatus } from "../../utils";
import HuswellShield from "../HuswellShield";
import ReservationDetailContent from "../ReservationDetailContent";
import ReservationDetailOwner from "../ReservationDetailOwner";
import { formatDateRange } from "../utils";

interface Props {
  listingId: string;
  reservationId: string;
  reservation: GuestyReservation["data"];
  hideBack?: boolean;
  useDesktopButtons?: boolean;
  onClose?: () => void;
}

interface FormData {
  note: string;
}

const defaultValues = {
  note: "",
};

const Reservation = ({
  reservation,
  hideBack,
  listingId,
  reservationId,
  useDesktopButtons,
  onClose,
}: Props) => {
  const [openRequestCancel, setOpenRequestCancel] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const intl = useIntl();

  const [presentAlert] = useIonAlert();
  const [isEditing, setIsEditing] = useState(false);

  const methods = useForm({
    defaultValues: {
      checkInDate: "",
      checkOutDate: "",
      reservationType: ReservationType.Myself,
      numberOfGuests: 1,
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    if (reservation) {
      methods.reset({
        checkInDate: format(
          parseISO(reservation.attributes.checkInDate),
          "yyyy-MM-dd",
        ),
        checkOutDate: format(
          parseISO(reservation.attributes.checkOutDate),
          "yyyy-MM-dd",
        ),
        reservationType: ReservationType.Myself,
        numberOfGuests: reservation.attributes.numberOfGuests,
      });
    }
  }, [isEditing, reservation, methods]);

  const handleEditReservation = () => {
    setIsEditing(true);
  };

  const { requestCancelReservation, isLoading: isLoadingCancel } =
    useRequestCancelReservation();
  const { deleteReservation, isLoading: isLoadingDelete } =
    useDeleteReservation();

  const router = useIonRouter();
  const isOwner = reservation?.attributes.isOwnerReservation;

  const handleBack = () => {
    if (hideBack) {
      onClose?.();
    } else {
      router.goBack();
    }
  };

  const handleRequestCancellation = ({ note }: FormData) => {
    requestCancelReservation(
      {
        listingId: listingId ?? "",
        reservationId: reservationId ?? "",
        note,
      },
      {
        onSuccess: () => {
          setCancelSuccess(true);
        },
      },
    );
  };

  const handleDeleteConfirmation = () => {
    presentAlert({
      header: intl.formatMessage({
        id: "delete_reservation_alert_title",
      }),
      message: intl.formatMessage({
        id: "delete_reservation_alert_message",
      }),
      cssClass: "delete-alert",
      buttons: [
        { text: intl.formatMessage({ id: "button.cancel" }) },
        {
          text: intl.formatMessage({ id: "reservation.delete" }),
          handler: handleDeletereservation,
        },
      ],
    });
  };

  const handleDeletereservation = () => {
    deleteReservation({
      listingId: listingId ?? "",
      reservationId: reservationId ?? "",
    });

    handleBack();
  };

  const reservationStatus = getReservationStatus(
    reservation?.attributes.checkInDate ?? "",
    reservation?.attributes.checkOutDate ?? "",
    reservation?.attributes.status ?? "",
  );
  const canEdit = reservationStatus === ReservationStatus.Upcoming;

  const {
    updateReservation,
    isLoading: isLoadingUpdate,
    error: updateError,
  } = useUpdateReservation();

  const handleUpdate = () => {
    const { checkInDate, checkOutDate, numberOfGuests } = methods.getValues();

    updateReservation(
      {
        listingId,
        reservationId,
        attributes: {
          checkInDate,
          checkOutDate,
          numberOfGuests,
          cleaningRequired: false,
        },
      },
      {
        onSuccess: () => setIsEditing(false),
        onError: (error) => {
          captureMessage("Error updating reservation in ReservationDetail", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  const buttons = (
    <div className="owner-buttons">
      {isEditing ? (
        <>
          <IonButton
            className="owner-button"
            data-testid="cancel-edit-reservation"
            shape="round"
            color="light"
            onClick={() => setIsEditing(false)}
          >
            <FormattedMessage id="button.cancel" />
          </IonButton>
          <LoadingButton
            className="owner-button"
            data-testid="update-reservation"
            form="hook-form-details"
            shape="round"
            color="primary"
            onClick={methods.handleSubmit(handleUpdate)}
            isLoading={isLoadingUpdate}
            disabled={isLoadingUpdate}
          >
            <FormattedMessage id="common.save" />
          </LoadingButton>
        </>
      ) : (
        <>
          <LoadingButton
            className="owner-button"
            data-testid="cancel-reservation"
            shape="round"
            color="danger"
            onClick={handleDeleteConfirmation}
            isLoading={isLoadingDelete}
            disabled={isLoadingDelete}
          >
            <FormattedMessage id="reservation_detail.cancel_reservation" />
          </LoadingButton>

          <IonButton
            className="owner-button"
            data-testid="edit-reservation"
            shape="round"
            onClick={handleEditReservation}
          >
            <FormattedMessage id="reservation_detail.edit_reservation" />
          </IonButton>
        </>
      )}
    </div>
  );

  return (
    <>
      {reservation && (
        <div className="reservation-detail-page">
          <div className="detail-container">
            {!hideBack && (
              <IonButton
                shape="round"
                className="back-button"
                onClick={handleBack}
              >
                <IonIcon
                  slot="icon-only"
                  icon="assets/icons/icon-chevron-back.svg"
                />
              </IonButton>
            )}
            <div className="detail-header">
              <div className="detail-title">
                <div>
                  <ReservationStatusChip
                    status={reservation.attributes.status}
                    checkInDate={reservation.attributes.checkInDate}
                    checkOutDate={reservation.attributes.checkOutDate}
                  />
                </div>
                <p className="margin-0 nickname">
                  {reservation.relationships.listing.nickname}
                </p>
                <h3>
                  {isOwner ? (
                    <FormattedMessage id="reservation_detail.owner_title" />
                  ) : (
                    reservation.attributes.guests.mainGuestName
                  )}
                </h3>

                <div className="reservation-type">
                  <IonIcon
                    className="ellipse-icon"
                    color={isOwner ? "secondary" : "success"}
                    src="assets/icons/icon-ellipse.svg"
                  />
                  <p className="margin-0 bold">
                    <FormattedMessage
                      id="reservation_detail.type"
                      values={{
                        isOwner,
                        dateRange: formatDateRange(
                          reservation.attributes.checkInDate,
                          reservation.attributes.checkOutDate,
                        ),
                        guests: reservation.attributes.numberOfGuests,
                      }}
                    />
                  </p>
                </div>
              </div>
              <div className={useDesktopButtons ? "" : "ion-hide-md-down"}>
                {isOwner && canEdit && buttons}
                {!isOwner && canEdit && (
                  <IonButton
                    className="continue-button-stepper"
                    data-testid="request-cancellation"
                    shape="round"
                    onClick={() => setOpenRequestCancel(true)}
                  >
                    <FormattedMessage id="reservation_detail.request_cancellation" />
                  </IonButton>
                )}
              </div>
            </div>
            <HuswellShield
              reservationConfirmed={
                reservation.attributes.status === "confirmed"
              }
              contractSigned={
                reservation.attributes.guests.requiredDocumentsUploaded
              }
              identityVerified={reservation.attributes.guests.identityVerified}
            />
            {isEditing ? (
              <FormProvider {...methods}>
                <ReservationDelayDisclaimer />
                <ReservationEditDetailsForm
                  onSubmit={handleUpdate}
                  error={updateError}
                />
              </FormProvider>
            ) : isOwner ? (
              <ReservationDetailOwner
                checkInDate={reservation.attributes.checkInDate}
                checkOutDate={reservation.attributes.checkOutDate}
                numberOfNights={reservation.attributes.numberOfNights}
                mainGuestName={reservation.attributes.guests.mainGuestName}
                numberOfGuests={reservation.attributes.numberOfGuests}
              />
            ) : (
              <ReservationDetailContent
                rentalRevenue={reservation.attributes.rentalRevenue}
                revenue={reservation.attributes.revenue}
                bookingReference={reservation.attributes.confirmationCode}
                checkInDate={reservation.attributes.checkInDate}
                checkOutDate={reservation.attributes.checkOutDate}
                numberOfNights={reservation.attributes.numberOfNights}
                otaName={reservation.relationships.ota.name}
                numberOfGuests={reservation.attributes.numberOfGuests}
                mainGuestName={reservation.attributes.guests.mainGuestName}
                email={reservation.attributes.guests.mainGuestEmail}
                phoneNumber={reservation.attributes.guests.mainGuestPhone}
                publicReview={
                  reservationStatus === ReservationStatus.Completed
                    ? reservation.attributes.publicReview
                    : undefined
                }
              />
            )}
          </div>
        </div>
      )}
      {reservation && canEdit && (
        <div
          className={`${useDesktopButtons ? "ion-hide" : "ion-hide-md-up"} reservation-detail-mobile-buttons`}
        >
          {isOwner && buttons}
          {!isOwner && (
            <IonButton
              className="width-100 owner-button"
              data-testid="request-cancellation"
              shape="round"
              onClick={() => setOpenRequestCancel(true)}
            >
              <FormattedMessage id="reservation_detail.request_cancellation" />
            </IonButton>
          )}
        </div>
      )}
      {openRequestCancel && (
        <Modal
          isOpen={openRequestCancel}
          onDidDismiss={() => setOpenRequestCancel(false)}
          continueButton={
            cancelSuccess ? undefined : (
              <LoadingButton
                data-testid="continue-button"
                className="continue-button-stepper"
                shape="round"
                expand="full"
                type="submit"
                form="request-cancel-form"
                disabled={isLoadingCancel}
                isLoading={isLoadingCancel}
              >
                <FormattedMessage id="common.continue" />
              </LoadingButton>
            )
          }
        >
          <div className="request-cancel-modal">
            <h5>
              <FormattedMessage id="reservation_detail.modal.title" />
            </h5>
            <p className="description">
              <FormattedMessage id="reservation_detail.modal.description" />
            </p>

            <Form<FormData>
              formId="request-cancel-form"
              onSubmit={handleRequestCancellation}
              defaultValues={defaultValues}
              mode="onSubmit"
            >
              <FormIonTextArea
                name="note"
                labelPlacement="floating"
                fill="solid"
                maxlength={500}
                label={intl.formatMessage({ id: "note.label" })}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      id: "request_cancellation_note.required",
                    }),
                  },
                }}
              />
            </Form>
            {cancelSuccess && (
              <div className="margin-top-24">
                <SuccessCard title="request_cancellation.success" />
              </div>
            )}
          </div>
        </Modal>
      )}
      {/* backdrop is not ideal in calendar, should be full page if possible but is now only covering the first modal above */}
      <div
        style={{
          position: "fixed",
          inset: 0,
          background: "black",
          opacity: openRequestCancel ? 0.5 : 0,
          zIndex: 11,
          transition: "opacity 0.3s",
          pointerEvents: "none",
        }}
      />
    </>
  );
};

export default Reservation;
