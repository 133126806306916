import { useState } from "react";

import { useBlockReasons } from "queries/blocks";

import CalendarContent from "components/@calendar/CalendarContent/CalendarContent";
import CalendarSidebar from "components/@calendar/CalendarSidebar/CalendarSidebar";

const CalendarContainer = () => {
  useBlockReasons(); // preload
  const [height, setHeight] = useState(642);

  const [selectedListingId, setSelectedListingId] = useState<string | null>(
    null,
  );

  return (
    <div
      className="calendar-container"
      style={{ height: "100%", minHeight: height, display: "flex" }}
    >
      <CalendarSidebar
        activeListingId={selectedListingId}
        setActiveListingId={setSelectedListingId}
      />

      <CalendarContent
        selectedListingId={selectedListingId}
        setHeight={setHeight}
      />
    </div>
  );
};

export default CalendarContainer;
