import { useMutation } from "@tanstack/react-query";
import {
  sendUserAttributeVerificationCode,
  VerifiableUserAttributeKey,
} from "aws-amplify/auth";

export const useVerifyEmail = () => {
  const { isPending, isPaused, mutate, error } = useMutation({
    mutationFn: (userAttributeKey: VerifiableUserAttributeKey) =>
      sendUserAttributeVerificationCode({ userAttributeKey }),
  });

  return {
    verifyEmail: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
