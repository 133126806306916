import "./SleepingArrangementsForm.scss";

import { IonButton, IonIcon } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { trash } from "ionicons/icons";
import { useCallback, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import {
  CompleteListingProviderValues,
  RoomType,
  SleepingArrangement,
} from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";

import BedTypes from "./BedTypes";

interface Props {
  error: Error | null;
  onSubmit: (values: {
    sleepingArrangements: Array<SleepingArrangement>;
    bedrooms: number;
    beds: number;
  }) => void;
}

const SleepingArrangementsForm = ({ error, onSubmit }: Props) => {
  const { getValues, handleSubmit, control } =
    useFormContext<CompleteListingProviderValues>();

  const { fields, append, remove } = useFieldArray({
    name: "sleepingArrangements",
    control,
  });

  const hasAppendedRooms = useRef(false);

  const [hasBedroomError, setHasBedroomError] = useState(false);

  const submit = () => {
    const sleepingArrangementsValues = getValues("sleepingArrangements");
    if (
      sleepingArrangementsValues.some(
        (field) =>
          !field.bedTypes.length ||
          field.bedTypes.some((type) => !type || typeof type !== "string"),
      )
    ) {
      return setHasBedroomError(true);
    }

    setHasBedroomError(false);

    const sleepingArrangements = sleepingArrangementsValues.map(
      (arrangement) => ({
        roomType: arrangement.roomType,
        bedTypes: arrangement.bedTypes.filter(
          (type) => (type as string) !== "",
        ),
      }),
    );

    const bedrooms = fields.filter(
      (field) => field.roomType === RoomType.Bedroom,
    ).length;

    const beds = sleepingArrangements.reduce((acc, curr) => {
      return acc + curr.bedTypes.length;
    }, 0);

    onSubmit({ sleepingArrangements, bedrooms, beds });
  };

  const addRoomType = useCallback(
    (roomType: RoomType) => {
      append({ roomType, bedTypes: [] });
      hasAppendedRooms.current = true;
    },
    [append],
  );

  const removeRoom = (index: number) => {
    remove(index);
    hasAppendedRooms.current = true;
  };

  return (
    <div className="sleeping-arrangements">
      {error && (
        <ErrorCard title={<FormattedMessage id="common.error_unknown" />} />
      )}
      {hasBedroomError && (
        <ErrorCard
          title={
            <FormattedMessage id="complete_listing_sleeping_arrangements.bedroom_error" />
          }
        />
      )}

      <form id="hook-form-arrangements" onSubmit={handleSubmit(submit)}>
        {fields.map((field, index) => (
          <div key={field.id} className="room-card">
            <div className="room-card-title">
              <h6>
                <FormattedMessage
                  id={`complete_listing_sleeping_arrangements.${field.roomType}`}
                  values={{
                    count: index + 1,
                  }}
                />
              </h6>
              <button
                className="delete-button"
                onClick={() => removeRoom(index)}
              >
                <IonIcon icon={trash} />
              </button>
            </div>

            <BedTypes
              name={`sleepingArrangements[${index}].bedTypes`}
              nestIndex={index}
            />
          </div>
        ))}

        <div className="room-type-buttons">
          <IonButton
            className="room-type-button"
            shape="round"
            onClick={() => addRoomType(RoomType.Bedroom)}
          >
            <IonIcon
              className="room-type-icon"
              slot="start"
              icon={addOutline}
            ></IonIcon>

            <FormattedMessage id="add.bedroom" />
          </IonButton>

          <IonButton
            className="room-type-button"
            shape="round"
            onClick={() => addRoomType(RoomType.SharedSpace)}
          >
            <IonIcon
              className="room-type-icon"
              slot="start"
              icon={addOutline}
            ></IonIcon>
            <FormattedMessage id="add.shared_space" />
          </IonButton>
        </div>
      </form>
    </div>
  );
};

export default SleepingArrangementsForm;
