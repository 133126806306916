import { IonButton, useIonRouter } from "@ionic/react";
import { useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { captureMessage } from "utils/sentry.utils";

import { Routes } from "constants/routes.constants";
import { useUpdateListing } from "queries";
import { Flow } from "types/flow.types";

import LoadingButton from "components/@common/LoadingButton";
import StepperContainer from "components/@common/StepperContainer";
import AmenitiesForm from "components/@complete-listing/Amenities/AmenitiesForm";

const Amenities = () => {
  const { updateListing, error, isLoading } = useUpdateListing();

  const router = useIonRouter();

  const listingId = useWatch({ name: "listingId" });

  const onSubmit = ({ amenities }: { amenities: Array<string> }) => {
    updateListing(
      { id: listingId, attributes: { amenities } },
      {
        onSuccess: () => {
          router.push(
            generatePath(Routes.CompleteListingSleepingArrangements, {
              listingId,
            }),
          );
        },
        onError: (error) => {
          captureMessage("Error updating amenities in listing completion", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  const handleBack = () => {
    router.push(
      generatePath(Routes.CompleteListingAbout, {
        listingId,
      }),
      "back",
    );
  };

  return (
    <StepperContainer
      flow={Flow.CompleteListing}
      route={Routes.CompleteListingAmenities}
      continueButton={
        <LoadingButton
          data-testid="continue-button"
          className="continue-button-stepper"
          type="submit"
          shape="round"
          expand="full"
          form="hook-form-amenities"
          isLoading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="complete_listing_amenities.title" />
      </h2>

      <p className="margin-0">
        <FormattedMessage id="complete_listing_amenities.description" />
      </p>

      <AmenitiesForm onSubmit={onSubmit} error={error} />
    </StepperContainer>
  );
};

export default Amenities;
