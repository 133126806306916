import { useQuery } from "@tanstack/react-query";

import { getCalendarQuery } from "./queries";

export const useCalendarListing = (
  id: string,
  startDate?: string,
  endDate?: string,
  options?: Partial<ReturnType<typeof getCalendarQuery>>,
) => {
  const { data, isLoading, error, isError } = useQuery({
    ...getCalendarQuery(id, startDate, endDate),
    ...options,
  });

  return {
    data: data?.data?.data,
    error,
    isLoading,
    isError,
  };
};
