import { IonPage } from "@ionic/react";

import { SignIn } from "components/@authentication/SignIn";
import LogoContainer from "components/@common/LogoContainer";

const Login = () => {
  return (
    <IonPage>
      <LogoContainer>
        <SignIn />
      </LogoContainer>
    </IonPage>
  );
};

export default Login;
