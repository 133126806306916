import { IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router";

import { isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";

import SignUpContinue from "pages/sign-up/Continue";
import CreateAccount from "pages/sign-up/CreateAccount";
import SignUpDetails from "pages/sign-up/Details";
import SignUpWelcome from "pages/sign-up/Welcome";

const SignUp = () => {
  return (
    <IonRouterOutlet id="sign-up" animated={isNative}>
      <Route path={Routes.SignUpContinue} component={SignUpContinue} />
      <Route path={Routes.CreateAccount} component={CreateAccount} />
      <Route path={Routes.SignUpDetails} component={SignUpDetails} />
      <Route path={Routes.SignUpWelcome} component={SignUpWelcome} />
    </IonRouterOutlet>
  );
};

export default SignUp;
