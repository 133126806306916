import { captureMessage } from "@sentry/react";
import { format } from "date-fns";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { useUpdateListing } from "queries";
import {
  Availabilities,
  DefaultSchema,
  ListingResponse,
} from "types/listing.types";

import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import LoadingButton from "components/@common/LoadingButton";
import { getListingId } from "components/@listing-detail/utils";
import AvailabilityForm from "components/@review-listing/Availability/AvailabilityForm";
import { ReviewListingAdvanceNotice } from "components/@review-listing/types";

type Props = {
  listing: ListingResponse["data"];
  isReadOnly: boolean;
};

const Availability = ({ listing, isReadOnly }: Props) => {
  const [hasDateError, setHasDateError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { updateListing, error, isLoading } = useUpdateListing();

  const methods = useForm<{ availabilities: Availabilities }>({
    defaultValues: { availabilities: listing.attributes.availabilities },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const onSubmit = ({ availabilities }: { availabilities: Availabilities }) => {
    const listingId = getListingId();
    const dateValues = methods.getValues("availabilities.customSchema");
    const { defaultSchema, bookingWindow, advanceNotice, customSchema } =
      availabilities;

    if (
      defaultSchema === DefaultSchema.Custom &&
      (!dateValues ||
        dateValues.some((field) => !field.startDate || !field.endDate))
    ) {
      setHasDateError(true);
      return;
    }

    setHasDateError(false);

    const newValue = {
      defaultSchema,
      bookingWindow:
        defaultSchema === DefaultSchema.Always ? bookingWindow : null,
      advanceNotice:
        advanceNotice === ReviewListingAdvanceNotice.None
          ? null
          : advanceNotice,
      customSchema: defaultSchema === DefaultSchema.Custom ? customSchema : [],
    };

    updateListing(
      {
        id: listingId,
        attributes: { availabilities: newValue },
      },
      {
        onSuccess: () => {
          methods.reset({ availabilities });
          setIsEditing(false);
        },
        onError: (error) => {
          captureMessage("Error updating availabilities in listing detail", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  const handleAction = () => {
    if (isEditing) {
      methods.reset({ availabilities: listing.attributes.availabilities });
    }

    setIsEditing((isEditing) => !isEditing);
  };

  const values = methods.watch("availabilities");

  return (
    <ItemList
      title="listing.settings.availability"
      actionKey={isEditing ? "button.cancel" : "common.edit"}
      onClick={isReadOnly ? undefined : handleAction}
    >
      {isEditing ? (
        <FormProvider {...methods}>
          <div className="review-availability">
            <AvailabilityForm
              error={error}
              onSubmit={onSubmit}
              hasDateError={hasDateError}
            />
          </div>

          <LoadingButton
            isLoading={isLoading}
            shape="round"
            style={{ height: 48, marginTop: 36 }}
            type="submit"
            form="hook-form-availability"
          >
            <FormattedMessage id="common.save" />
          </LoadingButton>
        </FormProvider>
      ) : (
        <>
          <ItemListRow title="review_listing_availability.availability.title">
            <FormattedMessage
              id={
                values.defaultSchema === DefaultSchema.Always
                  ? "availability.always"
                  : "availability.custom"
              }
            />
          </ItemListRow>
          {values.defaultSchema === DefaultSchema.Custom && (
            <ItemListRow title="review_listing_availability.periods.title">
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 40,
                  rowGap: 24,
                }}
              >
                {values.customSchema?.map((schema, index) => (
                  <div key={index}>
                    {format(schema.startDate, "d MMM yyyy")} -{" "}
                    {format(schema.endDate, "d MMM yyyy")}
                  </div>
                ))}
              </div>
            </ItemListRow>
          )}
        </>
      )}
    </ItemList>
  );
};

export default Availability;
