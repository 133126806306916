import { WeekRowType } from "types/calendar.types";

import CalendarDay from "components/@calendar/CalendarDay/CalendarDay";

type WeekRowProps = {
  weekRow: WeekRowType;
  selectedDate: Date;
};

const CalendarWeekRow = ({ weekRow, selectedDate }: WeekRowProps) => {
  return (
    <div
      role="row"
      style={{
        overflow: "hidden",
        display: "flex",
        flex: 1,
        borderBottom: "1px var(--ion-text-color-step-400) solid",
        borderRight: "1px var(--ion-text-color-step-400) solid",
      }}
    >
      {weekRow.map((day, index) => (
        <CalendarDay {...day} selectedDate={selectedDate} key={index} />
      ))}
    </div>
  );
};

export default CalendarWeekRow;
