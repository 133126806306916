import "../Calendar.scss";

import { IonSpinner } from "@ionic/react";
import { addMonths, format, subMonths } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { useResizeObserver } from "hooks";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useCalendarListing } from "queries";
import { useIntlStore } from "store";

import Calendar from "components/@calendar/Calendar/Calendar";
import CalendarDatePicker from "components/@calendar/CalendarDatePicker/CalendarDatePicker";
import CalendarLegend from "components/@calendar/Legend/Legend";
import { getCalendarValues } from "components/@calendar/utils";

interface Props {
  selectedListingId: string | null;
  setHeight?: (height: number) => void;
}

const CalendarContent = ({ selectedListingId, setHeight }: Props) => {
  const intl = useIntl();
  const currentLocale = useIntlStore((state) => state.locale);
  const calendarRef = useRef<HTMLDivElement>(null);
  const [_, _1, height] = useResizeObserver(calendarRef);

  const hasInitialised = useRef(false);
  useEffect(() => {
    if (hasInitialised.current) return;
    hasInitialised.current = true;
    setHeight?.(height);
  }, [setHeight, height]);

  const [activeDate, setActiveDate] = useState(new Date());
  const calendarValues = useMemo(
    () => getCalendarValues(activeDate),
    [activeDate],
  );

  const { data, isLoading } = useCalendarListing(
    selectedListingId || "",
    format(calendarValues.firstDayOfCalendar, "yyyy-MM-dd"),
    format(calendarValues.lastDayOfCalendar, "yyyy-MM-dd"),
    { enabled: !!selectedListingId },
  );

  // preload last and next month for smooth navigation between months
  const calendarValuesLastMonth = useMemo(
    () => getCalendarValues(subMonths(activeDate, 1)),
    [activeDate],
  );
  const calendarValuesNextMonth = useMemo(
    () => getCalendarValues(addMonths(activeDate, 1)),
    [activeDate],
  );
  useCalendarListing(
    selectedListingId || "",
    format(calendarValuesLastMonth.firstDayOfCalendar, "yyyy-MM-dd"),
    format(calendarValuesLastMonth.lastDayOfCalendar, "yyyy-MM-dd"),
    { enabled: !!selectedListingId },
  );
  useCalendarListing(
    selectedListingId || "",
    format(calendarValuesNextMonth.firstDayOfCalendar, "yyyy-MM-dd"),
    format(calendarValuesNextMonth.lastDayOfCalendar, "yyyy-MM-dd"),
    { enabled: !!selectedListingId },
  );

  const useShortDays = useMediaQuery("(max-width: 1100px)");
  const days = useMemo(() => {
    const DAYS = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    return DAYS.map((day) =>
      intl.formatMessage({
        id: `calendar.day.${useShortDays ? "short" : "full"}.${day}` as I18nKey,
      }),
    );
  }, [useShortDays, intl]);

  return (
    <div
      style={{ flex: 1, display: "flex", flexDirection: "column" }}
      ref={calendarRef}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingTop: 16,
        }}
      >
        <div
          className="calendar-header"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h6 className="month-title" style={{ color: "#280396" }}>
            {Intl.DateTimeFormat(currentLocale, { month: "long" }).format(
              activeDate,
            )}{" "}
            {activeDate.getFullYear()}
          </h6>

          <CalendarDatePicker
            value={activeDate}
            onChange={(date) => setActiveDate(new Date(date))}
          />
        </div>

        <div
          style={{
            display: "flex",
            borderBottom: "1px var(--ion-text-color-step-400) solid",
          }}
        >
          {days.map((day, i) => (
            <span
              className="calendar-day-title"
              key={i}
              style={{
                flex: 1,
                color: "var(--ion-text-color-step-700)",
              }}
            >
              {day}
            </span>
          ))}
        </div>
        <Calendar listing={data} activeDate={activeDate} />
      </div>

      <CalendarLegend selectedListingId={selectedListingId} />

      {isLoading && (
        <div
          style={{
            position: "fixed",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
            pointerEvents: "none",
            top: "0px",
            left: "0px",
          }}
        >
          <IonSpinner style={{ height: 100, width: 100 }} />
        </div>
      )}
    </div>
  );
};

export default CalendarContent;
