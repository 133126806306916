import { IonButton, useIonRouter } from "@ionic/react";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useUpdateListing } from "queries";
import { Flow } from "types/flow.types";
import { CompleteListingProviderValues } from "types/listing.types";
import { Context } from "types/upload.types";

import ErrorCard from "components/@common/ErrorCard";
import FileSelector from "components/@common/FileSelector";
import { MAX_FILES } from "components/@common/FileSelector/constants";
import LinkUnstyled from "components/@common/LinkUnstyled";
import LoadingButton from "components/@common/LoadingButton";
import SparkleCard from "components/@common/SparkleCard";
import StepperContainer from "components/@common/StepperContainer";

const Photos = () => {
  const router = useIonRouter();
  const { updateListing, error, isLoading } = useUpdateListing();
  const { setValue, getValues } =
    useFormContext<CompleteListingProviderValues>();
  const [isUploading, setIsUploading] = useState(false);

  const listingId = useWatch({ name: "listingId" });
  const imageUrls = useWatch<CompleteListingProviderValues>({
    name: "pictures",
  }) as Array<string>;

  const setImageUrls = (urls: Array<string>) => {
    setValue("pictures", urls);
  };

  const handleSubmit = () => {
    updateListing(
      { id: listingId, attributes: { pictures: getValues("pictures") } },
      {
        onSuccess: () => {
          router.push(
            generatePath(Routes.CompleteListingHouseRules, { listingId }),
          );
        },
      },
    );
  };

  const handleBack = () => {
    router.push(
      generatePath(Routes.CompleteListingSleepingArrangements, { listingId }),
      "back",
    );
  };

  const handleSkip = () => {
    router.push(generatePath(Routes.CompleteListingHouseRules, { listingId }));
  };

  return (
    <StepperContainer
      flow={Flow.CompleteListing}
      route={Routes.CompleteListingPhotos}
      continueButton={
        <LoadingButton
          data-testid="continue-button"
          className="continue-button-stepper"
          shape="round"
          expand="full"
          onClick={handleSubmit}
          disabled={isUploading}
          isLoading={isLoading}
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
      skipButton={
        <LinkUnstyled onClick={handleSkip}>
          <FormattedMessage id="button.skip.label" />
        </LinkUnstyled>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="complete_listing_photos.title" />
      </h2>

      <p className="create-listing-description">
        <FormattedMessage id="complete_listing_photos.description" />
      </p>

      <SparkleCard title="complete_listing_photos.sparkle.title" />

      <FileSelector
        listingId={listingId}
        context={Context.Onboarding}
        type="photos"
        maxFiles={MAX_FILES}
        fileUrls={imageUrls}
        setIsUploading={setIsUploading}
        updateFileUrls={setImageUrls}
      />

      {error && (
        <ErrorCard title={<FormattedMessage id={"common.error_unknown"} />} />
      )}
    </StepperContainer>
  );
};

export default Photos;
