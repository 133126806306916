import "./FormCounter.scss";

import { IonButton, IonIcon } from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";
import { ReactNode } from "react";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";

type Props = Pick<UseControllerProps, "rules" | "name"> & {
  label: ReactNode;
};

const FormCounter = ({ rules, name, label }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <div className="counter-container">
          <p className="counter-label margin-0">{label}</p>
          <div className="counter-controls">
            <IonButton
              onClick={() => onChange(value > 1 ? value - 1 : 1)}
              shape="round"
              className="counter-button"
              disabled={value === 1}
            >
              <IonIcon icon={removeOutline} />
            </IonButton>
            <p className="margin-0 counter-count">{value}</p>
            <IonButton
              onClick={() => onChange(value + 1)}
              shape="round"
              className="counter-button"
            >
              <IonIcon icon={addOutline} />
            </IonButton>
          </div>
        </div>
      )}
    />
  );
};

export default FormCounter;
