import { IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router";

import { isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";

import SignInContinue from "pages/sign-in/Continue";
import ForgotPassword from "pages/sign-in/ForgotPassword";
import Login from "pages/sign-in/Login";
import ResetPasswordConfirm from "pages/sign-in/ResetPasswordConfirm";
import ResetPasswordOtp from "pages/sign-in/ResetPasswordOtp";
import ResetPasswordSuccess from "pages/sign-in/ResetPasswordSuccess";
import VerifyEmail from "pages/sign-in/verifyEmail";

const SignIn = () => {
  return (
    <IonRouterOutlet id="sign-in" animated={isNative}>
      <Route path={Routes.SignInContinue} component={SignInContinue} />
      <Route path={Routes.Login} component={Login} />
      <Route path={Routes.ForgotPassword} component={ForgotPassword} />
      <Route path={Routes.ResetPassword} component={ResetPasswordOtp} />
      <Route
        path={Routes.ResetPasswordConfirm}
        component={ResetPasswordConfirm}
      />
      <Route
        path={Routes.ResetPasswordSuccess}
        component={ResetPasswordSuccess}
      />
      <Route path={Routes.VerifyEmail} component={VerifyEmail} />
    </IonRouterOutlet>
  );
};

export default SignIn;
