import "./Navigation.scss";

import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonSpinner,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  matchPath,
  Redirect,
  Route,
  useHistory,
  useLocation,
} from "react-router";

import { isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";
import { useIsEmailVerified } from "hooks/useIsEmailVerified";
import { useCurrentUser } from "services/auth/useCurrentUser";

import Calendar from "pages/calendar/Calendar";
import CompleteListing from "pages/complete-listing/Index";
import CreateListing from "pages/create-listing/Index";
import CreateReservation from "pages/create-reservation/Index";
import Listings from "pages/listings/Index";
import MobileMenu from "pages/mobile-menu/MobileMenu";
import Preferences from "pages/preferences/Preferences";
import ReservationDetail from "pages/reservations/ReservationDetail";
import Reservations from "pages/reservations/Reservations";
import ReviewListing from "pages/review-listing/Index";
import Callback from "pages/sign-in/Callback";
import SignIn from "pages/sign-in/SignIn";
import SignUp from "pages/sign-up/SignUp";

import {
  authenticatedRoutes,
  unauthenticatedRoutes,
  untabbedRoutes,
} from "components/@routes/AppRoutes/constants";
import { useTabs } from "components/@routes/AppRoutes/tabs";
import TopNav from "components/@routes/AppRoutes/TopNav";

const AppRoutes = () => {
  const location = useLocation();
  const tabs = useTabs("mobile");
  const history = useHistory();
  const { isLoggedIn, isLoading, user } = useCurrentUser();
  const profileCompleted = !!user?.data.attributes.profileCompleted;
  const [showNavigation, setShowNavigation] = useState(false);

  const emailVerified = useIsEmailVerified();

  useEffect(() => {
    const matches = untabbedRoutes.some((untabbedRoute) =>
      matchPath(location.pathname, { path: untabbedRoute }),
    );
    setShowNavigation(!matches);
  }, [location.pathname]);

  // Handle redirection based on authentication status. This is necessary because
  // IonRouterOutlet does not unmount components which can lead to unwanted side effects when using ProtectedRoutes
  // This effect will redirect to the correct route based on the authentication status and the profile completion status
  useEffect(() => {
    const isPathAuthenticated =
      !unauthenticatedRoutes.some((unauthenticatedRoute) =>
        matchPath(location.pathname, { path: unauthenticatedRoute }),
      ) ||
      authenticatedRoutes.some((authenticatedRoute) =>
        matchPath(location.pathname, { path: authenticatedRoute }),
      );

    if (!isLoading) {
      if (isPathAuthenticated && !isLoggedIn) {
        // Redirect unauthenticated user to login page
        return history.push(Routes.SignInContinue);
      } else if (
        !profileCompleted &&
        isLoggedIn &&
        location.pathname !== Routes.SignUpDetails &&
        location.pathname !== Routes.SignUpWelcome
      ) {
        return history.push(Routes.SignUpDetails);
      } else if (!isPathAuthenticated && isLoggedIn) {
        return history.push(Routes.Listings);
      }
    }
  }, [history, isLoading, isLoggedIn, location.pathname, profileCompleted]);

  if (isLoading) {
    return (
      <div
        style={{
          height: "100svh",
          width: "100svw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IonSpinner style={{ width: 60, height: 60 }} />
      </div>
    );
  }

  return (
    <div
      className={[
        showNavigation && "show-navigation",
        emailVerified === false ? "extra-header-padding" : "header-padding",
      ]
        .filter(Boolean)
        .join(" ")}
    >
      {showNavigation && <TopNav />}

      <IonTabs>
        <IonRouterOutlet animated={isNative}>
          {/* -- Routes with tabs -- */}

          <Listings />
          <Route exact path={Routes.Calendar} render={() => <Calendar />} />

          <Route
            exact
            path={Routes.Reservations}
            render={() => <Reservations />}
          />

          {/* -- Routes without tabs -- */}
          <Route exact path={Routes.MobileMenu} render={() => <MobileMenu />} />
          <Route
            exact
            path={Routes.Preferences}
            render={() => <Preferences />}
          />
          <Route
            exact
            path={Routes.ReservationDetails}
            render={() => <ReservationDetail />}
          />
          <Route
            path={Routes.CreateReservation}
            render={() => <CreateReservation />}
          />

          <Route path={Routes.Callback} render={() => <Callback />} />
          <Route path={Routes.SignIn} render={() => <SignIn />} />
          <Route path={Routes.SignUp} render={() => <SignUp />} />
          <Route path={Routes.CreateListing} render={() => <CreateListing />} />
          <Route
            path={Routes.CompleteListing}
            render={() => <CompleteListing />}
          />
          <Route path={Routes.ReviewListing} render={() => <ReviewListing />} />
          {/* -- End routes without tabs -- */}

          <Redirect exact from="/" to={Routes.Listings} />
        </IonRouterOutlet>

        {/* IonTabBar needs to be a direct child of IonTabs, so can't be moved to a separate file */}
        <IonTabBar
          slot="bottom"
          style={showNavigation ? {} : { display: "none" }}
        >
          {tabs.map(({ icon, label, route, disabled }) => (
            <IonTabButton
              key={route}
              tab={route}
              href={route}
              disabled={disabled}
            >
              <IonIcon src={icon} />
              <IonLabel>
                <FormattedMessage id={label} />
              </IonLabel>
            </IonTabButton>
          ))}
        </IonTabBar>
      </IonTabs>
    </div>
  );
};

export default AppRoutes;
