import "./MobileMenu.scss";

import { useIonRouter } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { accountLinksMap, supportLinksMap } from "constants/menu.constants";
import { useSignOut } from "queries";

import MenuItem from "components/@common/MenuItem";

const MobileMenu = () => {
  const { signOut, isLoading } = useSignOut();
  const router = useIonRouter();

  return (
    <div className="mobile-menu">
      <h3 className="menu-title">
        <FormattedMessage id="mobile_menu" />
      </h3>
      <p className="bold margin-bottom-8">
        <FormattedMessage id="mobile_menu_support.title" />
      </p>
      {supportLinksMap.map((link, index) => (
        <MenuItem
          key={index}
          onClick={() => window.open(link.href, "_blank")}
          {...link}
        />
      ))}
      <hr className="menu-divider" />
      <p className="bold margin-bottom-8">
        <FormattedMessage id="mobile_menu_account.title" />
      </p>
      {accountLinksMap.map((link, index) => (
        <MenuItem
          key={index}
          onClick={() => router.push(link.route)}
          {...link}
        />
      ))}
      <hr className="menu-divider" />

      <MenuItem
        title="mobile_menu_logout"
        icon="assets/icons/icon-logout.svg"
        isExternal={false}
        onClick={() => signOut()}
        isLoading={isLoading}
      />
    </div>
  );
};

export default MobileMenu;
