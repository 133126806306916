import "./FormRadioGroupOption.scss";

import {
  IonIcon,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonText,
} from "@ionic/react";
import { warning } from "ionicons/icons";
import { ReactNode } from "react";
import {
  Controller,
  FieldError,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { FormattedMessage } from "react-intl";

import MESSAGES_EN from "services/i18n/translations/en.json";

type Props = Pick<UseControllerProps, "rules" | "name"> & {
  options: Array<{ label: I18nKey | string | ReactNode; value: string }>;
  helperText?: string;
};

const FormRadioOptionGroup = ({
  rules,
  name,
  options,
  helperText,
  ...rest
}: Props) => {
  const { control } = useFormContext();

  const getHelperText = (error?: FieldError) => {
    if (helperText && !error?.message) {
      return helperText;
    }
    if (error?.message) return error.message;
  };

  const isI18nKey = (label: I18nKey | string | ReactNode): label is I18nKey => {
    return typeof label === "string" && MESSAGES_EN.hasOwnProperty(label);
  };

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div>
          <IonRadioGroup
            value={value}
            onIonChange={(e) => onChange(e.detail.value)}
            {...rest}
          >
            {options.map(({ label, value }) => (
              <IonItem lines="none" key={value} className="no-ripple">
                <IonRadio
                  value={value}
                  slot="start"
                  justify="start"
                  labelPlacement="end"
                >
                  <div className="ion-radio-label">
                    {isI18nKey(label) ? <FormattedMessage id={label} /> : label}
                  </div>
                </IonRadio>
              </IonItem>
            ))}
          </IonRadioGroup>

          {getHelperText(error) && (
            <div className="helper-text-container">
              <IonIcon color="danger" icon={warning} />
              <IonText color="danger">
                <p data-testid="helper-text" className="helper-text margin-0">
                  {getHelperText(error)}
                </p>
              </IonText>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default FormRadioOptionGroup;
