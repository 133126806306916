import { IonPage } from "@ionic/react";

import { ResetPasswordConfirm as ResetPasswordConfirmComponent } from "components/@authentication/ResetPasswordConfirm";
import LogoContainer from "components/@common/LogoContainer";

const ResetPasswordConfirm = () => {
  return (
    <IonPage>
      <LogoContainer>
        <ResetPasswordConfirmComponent />
      </LogoContainer>
    </IonPage>
  );
};

export default ResetPasswordConfirm;
