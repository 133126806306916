import "./Preferences.scss";

import { FormattedMessage, useIntl } from "react-intl";

import { useUser } from "queries";

import AppVersion from "components/@common/AppVersion";
import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import LanguageSelect from "components/@common/LanguageSelect";

const Preferences = () => {
  const intl = useIntl();
  const { data: user } = useUser();

  const onRequestData = () => {
    window.open(
      intl.formatMessage({ id: "preferences.privacy.mailto" }),
      "_blank",
    );
  };
  const onRequestDeactivation = () => {
    window.open(
      intl.formatMessage({ id: "preferences.deactivate.mailto" }),
      "_blank",
    );
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "64px 16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: 760,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <p
          className="margin-0"
          style={{
            marginBottom: 16,
            opacity: 0.5,
            fontWeight: 700,
            color: "#241261",
          }}
        >
          {user?.data.attributes.firstName} {user?.data.attributes.lastName}
        </p>
        <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
          <h3>
            <FormattedMessage id="mobile_menu_account.preferences" />
          </h3>

          <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
            <h6>
              <FormattedMessage id="preferences.language" />
            </h6>
            <ItemList>
              <ItemListRow title="preferences.language">
                <LanguageSelect />
              </ItemListRow>
            </ItemList>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
            <h6>
              <FormattedMessage id="preferences.privacy" />
            </h6>
            <ItemList>
              <ItemListRow
                title="preferences.privacy.title"
                actionKey="preferences.request"
                onActionClick={onRequestData}
              >
                <FormattedMessage id="preferences.privacy.description" />
              </ItemListRow>
            </ItemList>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: 24 }}
            className="request-deactivation"
          >
            <h6>
              <FormattedMessage id="preferences.account" />
            </h6>
            <ItemList>
              <ItemListRow
                title="preferences.account.title"
                actionKey="preferences.request"
                onActionClick={onRequestDeactivation}
              >
                <FormattedMessage id="preferences.account.description" />
              </ItemListRow>
            </ItemList>
          </div>

          <AppVersion />
        </div>
      </div>
    </div>
  );
};

export default Preferences;
