import { IonContent, IonPage } from "@ionic/react";

import VerifyEmailBanner from "components/@common/VerifyEmailBanner";
import ListingTableComponent from "components/@listing-table/ListingTable/ListingTable";

const ListingTable = () => {
  return (
    <IonPage>
      <IonContent className="listing-table-ion-content">
        <VerifyEmailBanner type="mobile" />
        <ListingTableComponent />
      </IonContent>
    </IonPage>
  );
};

export default ListingTable;
