import { IonButton, useIonRouter } from "@ionic/react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useMediaQuery } from "hooks/useMediaQuery";

import LinkUnstyled from "components/@common/LinkUnstyled";
import Modal from "components/@common/Modal";

const legendItems: Array<{ title: I18nKey; color: string }> = [
  {
    title: "calendar.guest_reservation",
    color: "var(--ion-color-success)",
  },
  {
    title: "calendar.owner_reservation",
    color: "var(--ion-color-secondary-shade)",
  },
  { title: "calendar.owner_block", color: "#DAD9D8" },
];

interface Props {
  selectedListingId: string | null;
}

const CalendarLegend = ({ selectedListingId }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const showMobileVersion = useMediaQuery("(max-width: 1000px)");
  const router = useIonRouter();

  const legend = (
    <>
      {legendItems.map(({ title, color }) => (
        <div
          key={title}
          style={{ display: "flex", alignItems: "center", gap: 4 }}
        >
          <div
            style={{
              backgroundColor: color,
              borderRadius: "100%",
              width: 14,
              height: 14,
            }}
          />
          <span style={{ fontSize: 14, fontWeight: 500 }}>
            <FormattedMessage id={title} />
          </span>
        </div>
      ))}
    </>
  );

  return (
    <div
      style={{
        padding: "4px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 16,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {showMobileVersion ? (
          <>
            <LinkUnstyled onClick={() => setIsDialogOpen(true)}>
              <FormattedMessage id="calendar.show_legend" />
            </LinkUnstyled>
            <Modal
              onDidDismiss={() => setIsDialogOpen(false)}
              isOpen={isDialogOpen}
            >
              <h5 style={{ marginBottom: 24 }}>
                <FormattedMessage id="calendar.show_legend" />
              </h5>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 16 }}
              >
                {legend}
              </div>
            </Modal>
          </>
        ) : (
          legend
        )}
      </div>

      {selectedListingId && (
        <IonButton
          shape="round"
          style={{ height: 50 }}
          onClick={() => {
            router.push(
              generatePath(
                Routes.CreateReservationType +
                  `?backroute=${window.location.pathname}`,
                {
                  listingId: selectedListingId,
                  reservationId: undefined,
                },
              ),
              "forward",
            );
          }}
        >
          <FormattedMessage id="calendar.create_reservation" />
        </IonButton>
      )}
    </div>
  );
};

export default CalendarLegend;
