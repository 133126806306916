import { IonButton, useIonRouter } from "@ionic/react";
import { useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { captureMessage } from "utils/sentry.utils";

import { Routes } from "constants/routes.constants";
import { useUpdateListing } from "queries";
import { Flow } from "types/flow.types";
import { SleepingArrangement } from "types/listing.types";

import LoadingButton from "components/@common/LoadingButton";
import StepperContainer from "components/@common/StepperContainer";
import SleepingArrangementsForm from "components/@complete-listing/SleepingArrangements/SleepingArrangementsForm";

const SleepingArrangements = () => {
  const { updateListing, error, isLoading } = useUpdateListing();
  const listingId = useWatch({ name: "listingId" });

  const router = useIonRouter();

  const onSubmit = ({
    sleepingArrangements,
    bedrooms,
    beds,
  }: {
    sleepingArrangements: Array<SleepingArrangement>;
    bedrooms: number;
    beds: number;
  }) => {
    updateListing(
      {
        id: listingId,
        attributes: { sleepingArrangements, bedrooms, beds },
      },
      {
        onSuccess: () => {
          router.push(
            generatePath(Routes.CompleteListingPhotos, {
              listingId,
            }),
          );
        },
        onError: (error) => {
          captureMessage(
            "Error updating sleeping arrangements in listing completion",
            { level: "error", extra: { error } },
          );
        },
      },
    );
  };

  const handleBack = () => {
    router.push(
      generatePath(Routes.CompleteListingAmenities, { listingId }),
      "back",
    );
  };

  const sleepingArrangements = useWatch({
    name: "sleepingArrangements",
  }) as Array<SleepingArrangement>;

  return (
    <StepperContainer
      flow={Flow.CompleteListing}
      route={Routes.CompleteListingSleepingArrangements}
      continueButton={
        <LoadingButton
          data-testid="continue-button"
          className="continue-button-stepper"
          type="submit"
          shape="round"
          expand="full"
          form="hook-form-arrangements"
          isLoading={isLoading}
          disabled={isLoading || !sleepingArrangements?.length}
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="complete_listing_sleeping_arrangements.title" />
      </h2>
      <p className="create-listing-description">
        <FormattedMessage id="complete_listing_sleeping_arrangements.description" />
      </p>

      <SleepingArrangementsForm error={error} onSubmit={onSubmit} />
    </StepperContainer>
  );
};

export default SleepingArrangements;
