import { IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router";

import { isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";

import ReviewListingProvider from "components/@review-listing/ReviewListingProvider";

import Availability from "./Availability";
import Launchpad from "./Launchpad";
import Showcase from "./Showcase";
import Success from "./Success";

const ReviewListing = () => {
  return (
    <ReviewListingProvider>
      <IonRouterOutlet id="review-listing" animated={isNative}>
        <Route path={Routes.ReviewListingLaunchpad} component={Launchpad} />
        <Route path={Routes.ReviewListingShowcase} component={Showcase} />
        <Route
          path={Routes.ReviewListingAvailability}
          component={Availability}
        />
        <Route path={Routes.ReviewListingSuccess} component={Success} />
      </IonRouterOutlet>
    </ReviewListingProvider>
  );
};

export default ReviewListing;
