import { IonContent, IonPage } from "@ionic/react";

import MobileMenuComponent from "components/@mobile-menu/MobileMenu/MobileMenu";

const MobileMenu = () => {
  return (
    <IonPage>
      <IonContent>
        <MobileMenuComponent />
      </IonContent>
    </IonPage>
  );
};

export default MobileMenu;
