import "./ListingTable.scss";

import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useIsEmailVerified } from "hooks/useIsEmailVerified";
import { useUser } from "queries";

import ListingTableRow from "components/@listing-table/ListingTableRow/ListingTableRow";

const ListingTable = () => {
  const { data: user, isLoading } = useUser();
  const router = useIonRouter();
  const emailVerified = useIsEmailVerified();

  const listings = user?.data.relationships.listings.data || [];

  if (!listings.length && !isLoading) {
    return (
      <div
        className={`listing-table-empty ${emailVerified ? "height-100" : ""}`}
      >
        <IonIcon icon="assets/icons/icon-nav-listing.svg" />
        <h6>
          <FormattedMessage id="listing_overview.empty.title" />
        </h6>
        <p>
          <FormattedMessage id="listing_overview.empty.description" />
        </p>
        <IonButton
          data-testid="create-listing"
          shape="round"
          onClick={() =>
            router.push(generatePath(Routes.CreateListingLaunchpad))
          }
        >
          <FormattedMessage id="listing_overview.add_listing" />
        </IonButton>
      </div>
    );
  }

  return (
    <div className="listing-table">
      <div className="header">
        <h5>
          <FormattedMessage id="nav.listings" />
        </h5>

        <IonButton
          className="continue-button-stepper"
          data-testid="create-listing"
          shape="round"
          onClick={() =>
            router.push(generatePath(Routes.CreateListingLaunchpad))
          }
        >
          <FormattedMessage id="listing_overview.add_listing" />
        </IonButton>
      </div>

      <div className="listings">
        {listings.map(({ id, attributes }) => (
          <ListingTableRow
            key={id}
            listingId={id}
            nickname={attributes.nickname}
          />
        ))}
      </div>
    </div>
  );
};

export default ListingTable;
