import { IonPage } from "@ionic/react";

import { SignInContinue } from "components/@authentication/SignInContinue";
import LogoContainer from "components/@common/LogoContainer";

const Continue = () => {
  return (
    <IonPage>
      <LogoContainer>
        <SignInContinue />
      </LogoContainer>
    </IonPage>
  );
};

export default Continue;
