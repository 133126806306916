/* eslint-disable no-console */
import { IonButton, useIonRouter } from "@ionic/react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useListingScraper } from "queries/scraper";
import { useCurrentUser } from "services/auth/useCurrentUser";
import { Flow } from "types/flow.types";
import { CreateListingProviderValues } from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";
import LinkUnstyled from "components/@common/LinkUnstyled";
import LoadingButton from "components/@common/LoadingButton";
import SparkleCard from "components/@common/SparkleCard";
import StepperContainer from "components/@common/StepperContainer";
import { FormIonInput } from "components/@form/FormIonInput";

import SupportedUrls from "./SupportedUrls";
import { validateUrl } from "./utils";

const Url = () => {
  const router = useIonRouter();
  const intl = useIntl();
  const { triggerScraper, error, isLoading } = useListingScraper();
  const { user } = useCurrentUser();

  const { handleSubmit, setValue } =
    useFormContext<CreateListingProviderValues>();
  const listingId = useWatch({ name: "listingId" });
  const url = useWatch({ name: "originalUrl" });

  const onSubmit = (data: CreateListingProviderValues) => {
    triggerScraper(
      {
        url: data.originalUrl,
        listingId: listingId,
        ownerId: user?.data.id || "",
      },
      {
        onSuccess: () => {
          // TODO: create helper function to go to next step and previous step
          router.push(
            generatePath(Routes.CreateListingRequirements, {
              listingId,
            }),
          );
        },
      },
    );
  };

  const handleBack = () => {
    router.push(
      generatePath(Routes.CreateListingPropertyDetails, {
        listingId,
      }),
      "back",
    );
  };

  const handleSkip = () => {
    if (!validateUrl(url)) setValue("originalUrl", "");

    router.push(
      generatePath(Routes.CreateListingRequirements, {
        listingId,
      }),
    );
  };

  return (
    <StepperContainer
      flow={Flow.CreateListing}
      route={Routes.CreateListingUrl}
      continueButton={
        <LoadingButton
          data-testid="continue-button"
          className="continue-button-stepper"
          type="submit"
          form="hook-form-url"
          shape="round"
          expand="full"
          disabled={!url || isLoading}
          isLoading={isLoading}
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
      skipButton={
        <LinkUnstyled onClick={handleSkip}>
          <FormattedMessage id="button.skip.label" />
        </LinkUnstyled>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="create_listing_url.title" />
      </h2>

      <p className="margin-0">
        <FormattedMessage id="create_listing_url.description" />
      </p>

      <SparkleCard title="create_listing_url.sparkle.title" />

      <form id="hook-form-url" onSubmit={handleSubmit(onSubmit)}>
        <FormIonInput
          data-testid="url-input"
          name="originalUrl"
          aria-label="url-input"
          autocomplete="url"
          type="url"
          rules={{
            validate: {
              validUrl: (value) =>
                validateUrl(value) ||
                intl.formatMessage({ id: "url.error.not_supported" }),
            },
          }}
          fill="solid"
          placeholder={intl.formatMessage({
            id: "url_input.placeholder",
          })}
          label={intl.formatMessage({ id: "url.label" })}
          labelPlacement="floating"
        />
      </form>

      <SupportedUrls />

      {error && (
        <div className="margin-top-16">
          <ErrorCard title={<FormattedMessage id={"common.error_unknown"} />} />
        </div>
      )}
    </StepperContainer>
  );
};

export default Url;
