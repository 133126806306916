import { IonFabButton, IonIcon } from "@ionic/react";
import { useEffect, useMemo, useState } from "react";

import { useListing, useUser } from "queries";

import CalendarSidebarItem from "components/@calendar/CalendarSidebar/CalendarSidebarItem";
import Modal from "components/@common/Modal";

type Props = {
  activeListingId: string | null;
  setActiveListingId: (id: string) => void;
};

const CalendarSidebar = ({ activeListingId, setActiveListingId }: Props) => {
  // This component becomes a header when below 768px (see div.calendar-sidebar-mobile)
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data: user } = useUser();
  const listings = useMemo(
    () => user?.data.relationships.listings.data || [],
    [user],
  );

  const { data: activeListing } = useListing(activeListingId || "", {
    enabled: !!activeListingId,
  });

  useEffect(() => {
    if (listings.length && !activeListingId) {
      setActiveListingId(listings[0].id);
    }
  }, [listings, activeListingId, setActiveListingId]);

  if (listings.length === 1) return null;

  const listingsList = listings.map((listing) => (
    <CalendarSidebarItem
      key={listing.id}
      id={listing.id}
      nickname={listing.attributes.nickname}
      isActive={activeListingId === listing.id}
      onClick={() => {
        setActiveListingId(listing.id);
        setIsDialogOpen(false);
      }}
    />
  ));

  return (
    <>
      <div
        className="calendar-sidebar"
        style={{
          overflowY: "scroll",
          flexDirection: "column",
          borderRight: "1px var(--ion-text-color-step-400) solid",
          marginRight: -1,
          gap: 16,
        }}
      >
        {listingsList}
      </div>

      <div
        className="calendar-sidebar-mobile"
        style={{
          margin: "20px 16px 4px 16px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            style={{
              fontSize: 13,
              lineHeight: "100%",
              fontWeight: 500,
              color: "var(--ion-text-color)",
            }}
          >
            {activeListing?.attributes.nickname}
          </span>
          <span
            style={{
              fontSize: 16,
              lineHeight: "160%",
              fontWeight: 600,
              color: "var(--ion-color-primary)",
            }}
          >
            {activeListing?.attributes.title}
          </span>
        </div>

        <IonFabButton
          color="light"
          style={{ height: 44, width: 44 }}
          onClick={() => setIsDialogOpen(true)}
        >
          <IonIcon color="primary" icon="assets/icons/icon-nav-menu.svg" />
        </IonFabButton>
        <Modal
          isOpen={isDialogOpen}
          onDidDismiss={() => setIsDialogOpen(false)}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            {listingsList}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default CalendarSidebar;
