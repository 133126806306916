import { IonButton, useIonRouter } from "@ionic/react";
import { useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { captureMessage } from "utils/sentry.utils";

import { Routes } from "constants/routes.constants";
import { useAmenities, useUpdateListing } from "queries";
import { Flow } from "types/flow.types";
import { HouseRule } from "types/listing.types";

import LoadingButton from "components/@common/LoadingButton";
import SparkleCard from "components/@common/SparkleCard";
import StepperContainer from "components/@common/StepperContainer";
import HouseRulesForm from "components/@complete-listing/HouseRules/HouseRulesForm";

const HouseRules = () => {
  useAmenities();
  const { updateListing, error, isLoading } = useUpdateListing();

  const router = useIonRouter();

  const listingId = useWatch({ name: "listingId" });

  const onSubmit = ({ houseRules }: { houseRules: Array<HouseRule> }) => {
    updateListing(
      {
        id: listingId,
        attributes: { houseRules },
      },
      {
        onSuccess: () => {
          router.push(
            generatePath(Routes.CompleteListingCompliance, {
              listingId,
            }),
          );
        },
        onError: (error) => {
          captureMessage("Error updating house rules in listing completion", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  const handleBack = () => {
    router.push(
      generatePath(Routes.CompleteListingPhotos, { listingId }),
      "back",
    );
  };

  return (
    <StepperContainer
      flow={Flow.CompleteListing}
      route={Routes.CompleteListingHouseRules}
      continueButton={
        <LoadingButton
          data-testid="continue-button"
          className="continue-button-stepper"
          type="submit"
          shape="round"
          expand="full"
          form="hook-form-rules"
          isLoading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="complete_listing_house_rules.title" />
      </h2>
      <p className="margin-0">
        <FormattedMessage id="complete_listing_house_rules.description" />
      </p>

      {!error && (
        <SparkleCard title="complete_listing_house_rules.sparkle.title" />
      )}

      <HouseRulesForm error={error} onSubmit={onSubmit} />
    </StepperContainer>
  );
};

export default HouseRules;
