import "./Requirements.scss";

import { IonButton, IonSpinner, useIonRouter } from "@ionic/react";
import DOMPurify from "dompurify";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { requirementsKeys } from "constants/listing.constants";
import { Routes } from "constants/routes.constants";
import { useRequirements } from "queries";
import { Flow } from "types/flow.types";
import {
  CreateListingProviderValues,
  RequirementsAnswer,
} from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";
import StepperContainer from "components/@common/StepperContainer";
import FormRadioOptionGroup from "components/@form/FormRadioGroupOption";

import { CreateListingRequirementsFormFields } from "../types";

const Requirements = () => {
  const router = useIonRouter();
  const { data, error, isLoading } = useRequirements();
  const intl = useIntl();

  const { setValue, handleSubmit } =
    useFormContext<CreateListingProviderValues>();

  const listingId = useWatch({ name: "listingId" });

  const onSubmit = (data: CreateListingProviderValues) => {
    setValue("requirements", data.requirements);

    const routePath = generatePath(
      data.requirements === RequirementsAnswer.Yes
        ? Routes.CreateListingConfirmPlan
        : Routes.CreateListingRequirementsFail,
      { listingId },
    );

    router.push(routePath);
  };

  const handleBack = () => {
    router.push(
      generatePath(Routes.CreateListingUrl, {
        listingId,
      }),
      "back",
    );
  };

  return (
    <StepperContainer
      flow={Flow.CreateListing}
      route={Routes.CreateListingRequirements}
      continueButton={
        <IonButton
          data-testid="continue-button"
          className="continue-button-stepper"
          shape="round"
          expand="full"
          type="submit"
          form="hook-form-requirements"
          disabled={!!error || isLoading}
        >
          <FormattedMessage id="common.continue" />
        </IonButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="create_listing_requirements.title" />
      </h2>

      <p className="create-listing-description">
        <FormattedMessage id="create_listing_requirements.description" />
      </p>

      {error && (
        <ErrorCard title={<FormattedMessage id={"common.error_unknown"} />} />
      )}

      {data && !isLoading && (
        <div
          className="requirements-content"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data),
          }}
        />
      )}

      {isLoading && (
        <div className="requirements-spinner-container">
          <IonSpinner className="spinner" name="crescent" />
        </div>
      )}

      <form id="hook-form-requirements" onSubmit={handleSubmit(onSubmit)}>
        <FormRadioOptionGroup
          name={CreateListingRequirementsFormFields.Requirements}
          options={[
            {
              label: requirementsKeys[RequirementsAnswer.Yes],
              value: RequirementsAnswer.Yes,
            },
            {
              label: requirementsKeys[RequirementsAnswer.No],
              value: RequirementsAnswer.No,
            },
          ]}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "requirements.error.required",
              }),
            },
          }}
        />
      </form>
    </StepperContainer>
  );
};

export default Requirements;
