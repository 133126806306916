import "./ListingDetailWrap.scss";

import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonText,
  IonToolbar,
  ScrollDetail,
  useIonRouter,
} from "@ionic/react";
import { matchPath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useListing } from "queries";

import { getListingId } from "../utils";

interface Props {
  children: React.ReactNode;
  onScroll: (scrollTop: number) => void;
}

const ListingDetailWrap = ({ children, onScroll }: Props) => {
  const router = useIonRouter();
  const isOverview = !!matchPath(location.pathname, {
    path: Routes.ListingOverview,
  });

  const listingId = getListingId();
  const { data, isLoading } = useListing(listingId ?? "", {
    enabled: !!listingId,
  });

  const listing = data?.attributes;

  const handleScroll = (event: CustomEvent<ScrollDetail>) => {
    const currentScrollTop = event.detail.scrollTop;
    onScroll(currentScrollTop);
  };

  return (
    <div className="ion-page listing-detail-wrap" id="listing-detail">
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButton
            slot="start"
            fill="clear"
            shape="round"
            onClick={() => router.push(Routes.Listings)}
          >
            <IonIcon
              slot="icon-only"
              color="primary"
              icon="assets/icons/icon-chevron-back.svg"
            />
          </IonButton>
          {isOverview && isLoading && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <div
                style={{
                  background: "var(--ion-text-color-step-300)",
                  height: 20,
                  width: 130,
                  borderRadius: 8,
                }}
              />
              <div
                style={{
                  background: "var(--ion-text-color-step-300)",
                  height: 24,
                  width: 190,
                  borderRadius: 8,
                }}
              />
            </div>
          )}
          {isOverview && !isLoading && (
            <div className="overview-nickname mobile-header-nickname">
              <p className="margin-0 small bold">
                <IonText color="primary">{listing?.nickname}</IonText>
              </p>

              <p className="margin-0 bold">{listing?.title}</p>
            </div>
          )}

          <IonMenuButton slot="end">
            <IonIcon color="primary" icon="assets/icons/icon-nav-menu.svg" />
          </IonMenuButton>
        </IonToolbar>
      </IonHeader>

      <IonContent
        scrollEvents
        onIonScroll={handleScroll}
        id="listing-content"
        className={`detail-wrap-content ${isOverview ? "overview" : ""}`}
      >
        <div className="listing-flex-container">
          <div className="listing-container">{children}</div>
        </div>
      </IonContent>
    </div>
  );
};

export default ListingDetailWrap;
