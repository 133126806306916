import { IonPage } from "@ionic/react";

import { ResetPasswordSuccess as ResetPasswordSuccessComponent } from "components/@authentication/ResetPasswordSuccess";
import LogoContainer from "components/@common/LogoContainer";

const ResetPasswordSuccess = () => {
  return (
    <IonPage>
      <LogoContainer>
        <ResetPasswordSuccessComponent />
      </LogoContainer>
    </IonPage>
  );
};

export default ResetPasswordSuccess;
