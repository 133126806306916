import { IonContent, IonPage } from "@ionic/react";

import ReservationsComponent from "components/@reservations/Reservations/Reservations";

const Reservations = () => {
  return (
    <IonPage>
      <IonContent className="reservations-page-ion-content">
        <ReservationsComponent />
      </IonContent>
    </IonPage>
  );
};

export default Reservations;
