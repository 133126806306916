import "./Availability.scss";

import { IonButton, IonIcon } from "@ionic/react";
import { addOutline, trash } from "ionicons/icons";
import { useCallback, useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import {
  advanceNoticeKeys,
  availibilityBookingWindowKeys,
} from "constants/listing.constants";
import { useAmenities } from "queries";
import { Availabilities, DefaultSchema } from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";
import SuccessCard from "components/@common/SuccessCard";
import { FormIonInput } from "components/@form/FormIonInput";
import FormRadioOptionGroup from "components/@form/FormRadioGroupOption";
import FormSelect from "components/@form/FormSelect";
import {
  ReviewListingAdvanceNotice,
  ReviewListingBookingWindow,
} from "components/@review-listing/types";

interface Props {
  error: Error | null;
  hasDateError: boolean;
  onSubmit: (data: { availabilities: Availabilities }) => void;
}

const AvailabilityForm = ({ error, hasDateError, onSubmit }: Props) => {
  const intl = useIntl();
  useAmenities();

  const { handleSubmit, control } = useFormContext<{
    availabilities: Availabilities;
  }>();

  const { fields, append, remove } = useFieldArray({
    name: "availabilities.customSchema",
    control,
  });

  const defaultSchema = useWatch({
    name: "availabilities.defaultSchema",
    defaultValue: DefaultSchema.Always,
  }) as DefaultSchema;

  const bookingWindowOptions = useMemo(
    () =>
      Object.values(ReviewListingBookingWindow).map((value) => ({
        label: intl.formatMessage({
          id: availibilityBookingWindowKeys[value] ?? value,
        }),
        value: value,
      })),
    [intl],
  );

  const advanceNoticeOptions = useMemo(
    () =>
      Object.values(ReviewListingAdvanceNotice).map((value) => ({
        label: intl.formatMessage({
          id: advanceNoticeKeys[value] ?? value,
        }),
        value: value,
      })),
    [intl],
  );

  const addrentalPeriod = useCallback(() => {
    append({ endDate: "", startDate: "" });
  }, [append]);

  return (
    <>
      {error && (
        <div className="margin-24">
          <ErrorCard title={<FormattedMessage id="common.error_unknown" />} />
        </div>
      )}

      <form id="hook-form-availability" onSubmit={handleSubmit(onSubmit)}>
        <p className="availability-subtitle bold">
          <FormattedMessage id="review_listing_availability.availability.title" />
        </p>

        <p className="extra-small margin-bottom-16">
          <FormattedMessage id="review_listing_availability.availability.subtitle" />
        </p>

        <FormRadioOptionGroup
          name="availabilities.defaultSchema"
          options={[
            {
              label: "availability.always",
              value: DefaultSchema.Always,
            },
            {
              label: "availability.custom",
              value: DefaultSchema.Custom,
            },
          ]}
        />

        {defaultSchema === DefaultSchema.Always && (
          <>
            <p className="availability-subtitle bold">
              <FormattedMessage id="review_listing_availability.window.title" />
            </p>

            <p className="extra-small margin-bottom-16">
              <FormattedMessage id="review_listing_availability.window.subtitle" />
            </p>

            <FormSelect
              name="availabilities.bookingWindow"
              options={bookingWindowOptions}
              placeholder={intl.formatMessage({
                id: "review_listing_availability.window.title",
              })}
            />
          </>
        )}

        {defaultSchema !== DefaultSchema.Always && (
          <>
            <p className="availability-subtitle bold">
              <FormattedMessage id="review_listing_availability.periods.title" />
            </p>

            <p className="extra-small margin-bottom-16">
              <FormattedMessage id="review_listing_availability.periods.subtitle" />
            </p>

            <SuccessCard title="review_listing_availability.periods.card" />

            {hasDateError && (
              <ErrorCard
                title={
                  <FormattedMessage id="review_listing_availability.date.error" />
                }
              />
            )}

            {fields.map((field, index) => (
              <div
                key={field.id}
                className="availability-container margin-bottom-16"
              >
                <div className="availability-items">
                  <div className="availability-item">
                    <FormIonInput
                      name={`availabilities.customSchema[${index}].startDate`}
                      data-testid="custom-schema-start-date-input"
                      aria-label="Start Date"
                      type="date"
                      fill="solid"
                      label={intl.formatMessage({ id: "start_date.label" })}
                      labelPlacement="floating"
                    />
                  </div>

                  <div className="availability-item">
                    <FormIonInput
                      name={`availabilities.customSchema[${index}].endDate`}
                      data-testid="custom-schema-end-date-input"
                      aria-label="End Date"
                      type="date"
                      fill="solid"
                      label={intl.formatMessage({ id: "end_date.label" })}
                      labelPlacement="floating"
                    />
                  </div>
                </div>

                <button
                  className="delete-button"
                  style={{ background: "none" }}
                  onClick={() => remove(index)}
                >
                  <IonIcon icon={trash} />
                </button>
              </div>
            ))}

            <IonButton
              className="contrast-button"
              shape="round"
              onClick={addrentalPeriod}
            >
              <IonIcon
                className="room-type-icon"
                slot="start"
                icon={addOutline}
              />

              <FormattedMessage id="add.rental_period" />
            </IonButton>
          </>
        )}

        <p className="availability-subtitle bold">
          <FormattedMessage id="review_listing_availability.notice.title" />
        </p>

        <p className="extra-small margin-bottom-16">
          <FormattedMessage id="review_listing_availability.notice.subtitle" />
        </p>

        <FormSelect
          name="availabilities.advanceNotice"
          options={advanceNoticeOptions}
          placeholder={intl.formatMessage({
            id: "review_listing_availability.notice.title",
          })}
        />
      </form>
    </>
  );
};

export default AvailabilityForm;
