import { IonPage } from "@ionic/react";

import { SignUpWelcome } from "components/@authentication/SignUpWelcome";

const Welcome = () => {
  return (
    <IonPage>
      <SignUpWelcome />
    </IonPage>
  );
};

export default Welcome;
