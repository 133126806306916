import { useMutation } from "@tanstack/react-query";
import { signUp } from "aws-amplify/auth";

import { CognitoUserParams } from "services/auth/types";
import { useIntlStore } from "store";

// This is only used incase of email signup
export const useCreateUser = () => {
  const locale = useIntlStore((state) => state.locale);
  const { isPending, isPaused, mutate, error } = useMutation({
    mutationFn: ({ username, password }: CognitoUserParams) =>
      signUp({
        username,
        password,
        options: {
          userAttributes: {},
          clientMetadata: { locale },
          autoSignIn: true,
        },
      }),
  });

  return {
    createUser: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
