import "./SignUpDetails.scss";

import { IonText, IonToast, useIonRouter } from "@ionic/react";
import DOMPurify from "dompurify";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { captureMessage } from "utils/sentry.utils";

import { Routes } from "constants/routes.constants";
import { emailRegex, phoneRegex } from "constants/validation.constants";
import { usePrivacyPolicy, useTermsAndConditions } from "queries";
import { useUpdateUser } from "queries/authentication/useUpdateUser";
import { useCurrentUser } from "services/auth/useCurrentUser";
import { useAuthStore } from "store/auth-store";

import ButtonUnstyled from "components/@common/ButtonUnstyled";
import LoadingButton from "components/@common/LoadingButton";
import Modal from "components/@common/Modal";
import { Form } from "components/@form/Form";
import { FormIonCheckbox } from "components/@form/FormIonCheckbox";
import { FormIonInput } from "components/@form/FormIonInput";

import { SignUpDetailsFormFields } from "../types";

interface FormData {
  firstName: string;
  lastName: string;
  phone: string;
  email?: string;
  subscribe: boolean;
}

const SignUpDetails = () => {
  const intl = useIntl();
  const { user } = useCurrentUser();
  const { updateUser, isLoading, error } = useUpdateUser();
  const router = useIonRouter();
  const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const { data: termsAndConditions } = useTermsAndConditions();
  const { data: privacyPolicy } = usePrivacyPolicy();

  const { loginProvider, setValues } = useAuthStore((state) => ({
    loginProvider: state.values?.loginProvider,
    setValues: state.actions.setValues,
  }));

  const defaultValues = {
    [SignUpDetailsFormFields.Email]: user?.data?.attributes?.email || "",
    [SignUpDetailsFormFields.FirstName]: user?.data.attributes.firstName || "",
    [SignUpDetailsFormFields.LastName]: user?.data.attributes.lastName || "",
    [SignUpDetailsFormFields.Phone]: "",
    [SignUpDetailsFormFields.Subscribe]: false,
    showEmail: !!loginProvider,
  };

  const handleSubmit = (data: FormData) => {
    if (user?.data.id) {
      updateUser(
        {
          userId: user.data.id,
          attributes: {
            ...(data.email && { email: data.email }),
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            // Invert the value because the checkbox is for unsubscribing
            marketingConsent: !data.subscribe,
            profileCompleted: true,
          },
        },
        {
          onSuccess: () => {
            setValues({ userName: data.firstName });
            router.push(Routes.SignUpWelcome);
          },
          onError: (updateError) => {
            captureMessage("Error updating user", {
              level: "error",
              extra: { error: updateError },
            });
          },
        },
      );
    }
  };

  const handleOpenTermsAndConditions = () => setOpenTermsAndConditions(true);
  const handleCloseTermsAndConditions = () => setOpenTermsAndConditions(false);
  const handleOpenPrivacyPolicy = () => setOpenPrivacyPolicy(true);
  const handleClosePrivacyPolicy = () => setOpenPrivacyPolicy(false);

  return (
    <div className="container">
      <IonText>
        <h2 className="title">
          <FormattedMessage id="sign_up_details.title" />
        </h2>
      </IonText>

      {error && (
        <IonToast
          color="danger"
          isOpen={!!error}
          message={intl.formatMessage({
            id: "common.error_unknown",
          })}
          duration={5000}
        />
      )}

      <Form<FormData>
        key={user?.data.id}
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        mode="onSubmit"
      >
        <FormIonInput
          data-testid="first-name-input"
          inputMode="text"
          name={SignUpDetailsFormFields.FirstName}
          aria-label={SignUpDetailsFormFields.FirstName}
          autocomplete="name"
          type="text"
          fill="solid"
          label={intl.formatMessage({ id: "first_name.label" })}
          labelPlacement="floating"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "first_name.error.required",
              }),
            },
          }}
          placeholder={intl.formatMessage({
            id: "sign_up_details.first_name.placeholder",
          })}
        />

        <FormIonInput
          data-testid="last-name-input"
          inputMode="text"
          name={SignUpDetailsFormFields.LastName}
          aria-label={SignUpDetailsFormFields.LastName}
          autocomplete="family-name"
          type="text"
          fill="solid"
          label={intl.formatMessage({ id: "last_name.label" })}
          labelPlacement="floating"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "last_name.error.required",
              }),
            },
          }}
          placeholder={intl.formatMessage({
            id: "sign_up_details.last_name.placeholder",
          })}
          hint={intl.formatMessage({
            id: "sign_up_details.last_name.hint",
          })}
          className="margin-top-8"
        />

        <FormIonInput
          data-testid="phone-input"
          inputMode="tel"
          name={SignUpDetailsFormFields.Phone}
          aria-label={SignUpDetailsFormFields.Phone}
          autocomplete="tel"
          type="tel"
          fill="solid"
          label={intl.formatMessage({ id: "phone.label" })}
          labelPlacement="floating"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "phone.error.required",
              }),
            },
            pattern: {
              value: phoneRegex,
              message: intl.formatMessage({
                id: "phone.error.invalid",
              }),
            },
          }}
          placeholder={intl.formatMessage({
            id: "sign_up_details.phone.placeholder",
          })}
          hint={intl.formatMessage({
            id: "sign_up_details.phone.hint",
          })}
        />

        {loginProvider && (
          <FormIonInput
            data-testid="email-input"
            inputMode="email"
            name={SignUpDetailsFormFields.Email}
            aria-label={SignUpDetailsFormFields.Email}
            autocomplete="email"
            type="email"
            fill="solid"
            label={intl.formatMessage({ id: "email.label" })}
            labelPlacement="floating"
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "login.field.email.error.required",
                }),
              },
              pattern: {
                value: emailRegex,
                message: intl.formatMessage({
                  id: "login.field.email.error.invalid",
                }),
              },
            }}
            placeholder={intl.formatMessage({
              id: "sign_up_continue.email.placeholder",
            })}
            hint={intl.formatMessage({
              id: "sign_up_details.email.hint",
            })}
          />
        )}

        <hr />

        <p className="margin-16">
          <FormattedMessage
            id="sign_up_details.terms_and_conditions.and.privacy_policy"
            values={{
              a: (chunks) => (
                <ButtonUnstyled
                  data-testid="terms-and-conditions-link"
                  onClick={handleOpenTermsAndConditions}
                >
                  {chunks}
                </ButtonUnstyled>
              ),
              a2: (chunks) => (
                <ButtonUnstyled
                  data-testid="privacy-policy-link"
                  onClick={handleOpenPrivacyPolicy}
                >
                  {chunks}
                </ButtonUnstyled>
              ),
            }}
          />
        </p>
        <LoadingButton
          data-testid="continue-button"
          className="continue-button"
          type="submit"
          shape="round"
          expand="full"
          isLoading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage id="sign_up_details.button.label" />
        </LoadingButton>

        <p className="margin-top-24 small">
          <FormattedMessage id="sign_up_details.checkbox.intro" />
        </p>

        <FormIonCheckbox
          data-testid="checkbox-input"
          labelPlacement="end"
          name={SignUpDetailsFormFields.Subscribe}
          aria-label={SignUpDetailsFormFields.Subscribe}
        >
          <p className="margin-0">
            <FormattedMessage id="sign_up_details.checkbox.label" />
          </p>
        </FormIonCheckbox>
      </Form>

      {termsAndConditions && openTermsAndConditions && (
        <Modal
          isOpen={openTermsAndConditions}
          onDidDismiss={handleCloseTermsAndConditions}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(termsAndConditions),
            }}
          />
        </Modal>
      )}

      {privacyPolicy && openPrivacyPolicy && (
        <Modal
          isOpen={openPrivacyPolicy}
          onDidDismiss={handleClosePrivacyPolicy}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(privacyPolicy),
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default SignUpDetails;
