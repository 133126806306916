import { IonSplitPane } from "@ionic/react";
import { useState } from "react";
import { matchPath, Route } from "react-router";

import { Routes } from "constants/routes.constants";

import ListingCalendar from "pages/listings/ListingCalendar";
import ListingDetails from "pages/listings/ListingDetails";
import ListingMarketing from "pages/listings/ListingMarketing";
import ListingOverview from "pages/listings/ListingOverview";
import ListingPlatforms from "pages/listings/ListingPlatforms";
import ListingSettings from "pages/listings/ListingSettings";
import ListingTable from "pages/listings/ListingTable";

import ListingDetailWrap from "components/@listing-detail/ListingDetailWrap/ListingDetailWrap";
import ListingOverviewHeader from "components/@listing-detail/ListingOverviewHeader/ListingOverviewHeader";
import ListingSidebar from "components/@listing-detail/ListingSidebar/ListingSidebar";

const Listings = () => {
  return (
    <>
      {/* Index page */}
      <Route exact path={Routes.Listings} component={ListingTable} />

      {/* Detail page with subroutes */}
      <Route path={Routes.ListingView} component={ListingView} />
    </>
  );
};

const ListingView = () => {
  const isOverview = !!matchPath(location.pathname, {
    path: Routes.ListingOverview,
  });
  const [scrollTop, setScrollTop] = useState(0);

  return (
    <>
      {isOverview && <ListingOverviewHeader isScrolled={scrollTop !== 0} />}

      <IonSplitPane
        id="splitview-listing"
        when="(min-width: 769px)"
        contentId="listing-detail"
      >
        <ListingSidebar />

        <ListingDetailWrap
          onScroll={(scrollTop: number) => setScrollTop(scrollTop)}
        >
          <Route path={Routes.ListingOverview} component={ListingOverview} />
          <Route path={Routes.ListingCalendar} component={ListingCalendar} />
          <Route path={Routes.ListingDetails} component={ListingDetails} />
          <Route path={Routes.ListingMarketing} component={ListingMarketing} />
          <Route path={Routes.ListingPlatforms} component={ListingPlatforms} />
          <Route path={Routes.ListingSettings} component={ListingSettings} />
        </ListingDetailWrap>
      </IonSplitPane>
    </>
  );
};

export default Listings;
