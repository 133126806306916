/* eslint-disable @typescript-eslint/no-explicit-any */
import "./StepperContainer.scss";

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonProgressBar,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import logo from "images/huswell-logo.svg";
import { closeOutline } from "ionicons/icons";
import { ReactNode } from "react";

import { isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";
import { Flow } from "types/flow.types";
import { Route } from "types/route.types";

import { getProgressValue } from "components/@create-listing/utils";

interface Props {
  children: ReactNode;
  continueButton?: ReactNode;
  backButton?: ReactNode;
  route?: Route;
  fullWidthContent?: boolean;
  skipButton?: ReactNode;
  flow: Flow;
  backRoute?: string;
}

//TODO: split this component into 2 containers, 1 with progress bar and 1 without -> also make the next & back buttons generic so we don't have to pass them as props
// https://bitbucket.org/appstrakt/husw-0001-digital-platform-react-ionic/pull-requests/76/diff#comment-thread-526928684

const StepperContainer = ({
  children,
  continueButton,
  backButton,
  route,
  fullWidthContent,
  skipButton,
  flow,
  backRoute,
}: Props) => {
  const router = useIonRouter();
  const value = getProgressValue(flow, route);

  const handleClose = () => {
    const route = backRoute || Routes.Listings;
    router.push(route, "forward");
  };

  return (
    <IonContent>
      <IonGrid className="ion-grid-stepper">
        {/* desktop row */}
        <IonRow className="ion-hide-md-down fixed-height">
          <IonCol className="ion-col-stepper">
            <button
              data-testid="huswell-logo-button-desktop"
              className="logo-button-desktop"
              onClick={() => router.push(Routes.SignInContinue, "back")}
            >
              <IonIcon
                color="primary"
                className="header-logo-stepper"
                icon={logo}
              />
            </button>
          </IonCol>
          <IonCol className="flex-end ion-col-stepper">
            <div className="close-icon-md">
              <IonButton
                shape="round"
                className="close-button"
                onClick={handleClose}
              >
                <IonIcon className="close-icon" icon={closeOutline} />
              </IonButton>
            </div>
          </IonCol>
        </IonRow>

        {/* mobile row */}
        <IonRow
          className="ion-hide-md-up relative-row"
          style={{ minHeight: isNative ? 110 : 60 }}
        >
          <div className="ion-hide-md-up close-icon-sm">
            <IonButton
              shape="round"
              className="close-button"
              onClick={handleClose}
            >
              <IonIcon className="close-icon" icon={closeOutline} />
            </IonButton>
          </div>
        </IonRow>

        {fullWidthContent ? (
          <IonRow className="full-width-content">
            <IonCol className="full-width-col">
              {!!value && (
                <div className="stepper-container">
                  <IonProgressBar value={value} type="determinate" />
                </div>
              )}
              {children}
            </IonCol>
          </IonRow>
        ) : (
          <IonRow className="flex-row">
            <IonCol className="content-container ion-col-stepper">
              {!!value && <IonProgressBar value={value} type="determinate" />}
              {children}
            </IonCol>
          </IonRow>
        )}

        {/* @TODO consider using IonFooter + IonToolbar */}
        {(continueButton || backButton) && (
          <IonRow className="button-row">
            <div className="button-container">
              {backButton}
              <div className="button-container-2">
                {skipButton}
                {continueButton}
              </div>
            </div>
          </IonRow>
        )}
      </IonGrid>
    </IonContent>
  );
};

export default StepperContainer;
